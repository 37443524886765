import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SettingState } from "../types";

const selectSettingState = (state: RootState): SettingState => state.setting;

export const selectSettings = createSelector(
  [selectSettingState],
  (setting) => setting.settings
);
