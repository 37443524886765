import { MESSAGE_TYPE } from "../constant/room";
import { UserMessage } from "../services";

export const isMessageContentValid = (message: UserMessage) => {
  return !!message.message.content || !!message.message.messageMeta;
};
export function parseJSON<T>(message?: string | null): T | null {
  try {
    if (!message || message === "undefined" || message.trim() === "") {
      return {} as T;
    }
    return JSON.parse(message) as T;
  } catch (e) {
    console.error("JSON parse error: ", e);
    return null;
  }
}

const extractTextFromHTML = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const textContent = doc.body.textContent?.trim() || "";
  return textContent || "Send a message";
};

export const refactorContent = (type: string, content: string): string => {
  switch (type) {
    case MESSAGE_TYPE.HTML:
      return extractTextFromHTML(content);
    case MESSAGE_TYPE.FILE:
      return "Attachment";
    default:
      return content || "";
  }
};

export const removeVietnameseTones = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};
