import { UserInfo } from "../contexts";
import { UserRoom } from "../services";
import { getChatUserInfoDisplayString, prefixEmail } from "./chat-user-helper";
import { getDisplayKeyFromPrefixString } from "./prefix-helper";

export const getUserRoomDisplayName = (userRoom: UserRoom, userId?: string) => {
  if (userId === userRoom?.userId) return "You";

  if (!userRoom) return "Unknown";
  const { nickname, User } = userRoom;
  return (
    nickname || getDisplayKeyFromPrefixString(User?.username || User?.email)
  );
};

export const getUserDisplayName = (item: UserRoom, userData: UserInfo) => {
  return [
    item?.nickname ||
      (item?.User?.firstname &&
        item?.User?.lastname &&
        `${item?.User?.firstname} ${item?.User?.lastname}`) ||
      prefixEmail(getChatUserInfoDisplayString(item?.User)),
    userData?.id === item?.User?.id ? "- You" : "",
    item?.isCreator ? "- Owner" : "",
  ]
    .filter(Boolean)
    .join(" ");
};
