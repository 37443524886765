import { HttpStatusCode } from "axios";
import {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Socket, connect } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
// import LocalStorageKey from "../constants/local-storage-key";
// import {
//   Room,
//   UserMessage,
//   UserRoom,
//   useChatStore,
// } from "../libs/zustands/useChatStore";
// import { useKeysStore } from "../libs/zustands/useKeysStore";
// import { useRoomCallStore } from "../libs/zustands/useRoomCallStore";
import { ChatAxios, getRequestHeaders } from "../services/axios";
// import { RoomCall, UserRoomCallStatus } from "../types/room-call";
// import { UserStream } from "../types/streams";
import { config } from "../config";
import { LocalStorageKey, SocketEventTypeEnum } from "../enums";
import { parseJSON } from "../helpers/message-helper";
import { selectUserData } from "../redux/selectors/auth";
import {
  selectRooms,
  selectSearchValue,
  selectSelectedRoom,
} from "../redux/selectors/chat";
import {
  selectMessageListByRoom,
  selectSelectedThread,
} from "../redux/selectors/message";
import {
  removeRoom,
  setChatUnreadCount,
  setSelectedRoom,
  upsertRoom,
  upsertSelectedRoom,
} from "../redux/slices/chat";
import {
  handleRemoveSendingMessage,
  removeMessage,
  setIdsRefresh,
  setMessageListByRoom,
  setSelectedThread,
  updateMessages,
} from "../redux/slices/message";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { RoomState } from "../redux/types";
import {
  LatestMessage,
  Room,
  RoomCall,
  UserMessage,
  UserRoom,
  UserRoomStatusType,
} from "../services";
import {
  handleDetailInfo,
  handleGetOneRoomNeedExchangeKey,
} from "../services/api";
import { ComputeRoomKeyRequest } from "../types/key-exchange";
import { DeviceInfo } from "../types/room";
import { mergeArrays } from "../utils/array";
import { handleDecryptMessageContent } from "../utils/encrypt";
import { getErrorMessage } from "../utils/error";
import { setLocalChatUnreadCount } from "../utils/local-storage";
import { sortMessages } from "../utils/sort";
import { useKeyExchangingContext } from "./KeyExchangingContext";
import { useRoomCallContext } from "./RoomCallContext";
import { ROOM_STATUS } from "../constant/room";

const ConnectionContext = createContext<{
  socketRef: RefObject<Socket> | null;
  socketReceivedData: any[];
  setSocketReceivedData: Dispatch<SetStateAction<any[]>>;
  sendSocketData: (data: any) => void;
}>({
  socketRef: null,
  socketReceivedData: [],
  setSocketReceivedData: () => {},
  sendSocketData: () => {},
});

export const ConnectionContextProvider = ({ children }: PropsWithChildren) => {
  const { chatUnreadCount } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData);
  const { roomKey } = useParams();
  const roomKeyRef = useRef<string>();
  const [socketReceivedData, setSocketReceivedData] = useState<any[]>([]);
  const socketReceivedDataRef = useRef<typeof socketReceivedData>([]);

  const {
    userKeyPair,
    roomEncryptKey,
    handleDoneGetShareKeyRoom,
    refReadyForPreprocessing,
    setRoomKeyExchangeStatus,
  } = useKeyExchangingContext();
  const socketRef = useRef<Socket | null>(null);

  const selectedRoom = useAppSelector(selectSelectedRoom);
  const rooms = useAppSelector(selectRooms);
  const {
    roomCallAttendees,
    setRoomCalls,
    setRoomCallAttendees,
    setIdRoomCall,
    roomCalls,
  } = useRoomCallContext();

  const selectedThread = useAppSelector(selectSelectedThread);
  const messageListByRoomPrev = useAppSelector(selectMessageListByRoom);
  const searchValue = useAppSelector(selectSearchValue);

  // const { publicStreams, setPublicStreams, roomStreams, setRoomStreams } =
  //   useStreamContext();

  // const { roomCallAttendees, roomCalls, setRoomCalls, setRoomCallAttendees } =
  //   useRoomCallStore();

  // const publicStreamsRef = useRef<typeof publicStreams>({});
  // const roomStreamsRef = useRef<typeof roomStreams>({});

  const messageListByRoomRef = useRef<typeof messageListByRoomPrev>({
    roomIds: [],
    rooms: {},
  });
  const roomListRef = useRef<RoomState>();

  const userKeyPairRef = useRef<typeof userKeyPair>({
    private_key: "",
    public_key: "",
  });
  const selectedRoomRef = useRef<typeof selectedRoom>();
  const selectedThreadRef = useRef<typeof selectedThread>();
  const searchValueRef = useRef<typeof searchValue>();
  const roomEncryptKeyRef = useRef<typeof roomEncryptKey>({});

  const roomCallsRef = useRef<typeof roomCalls>({});
  const roomCallAttendeesRef = useRef<typeof roomCallAttendees>({});

  const chatUnreadCountRef = useRef<typeof chatUnreadCount>({});

  // useEffect(() => {
  //   publicStreamsRef.current = publicStreams;
  // }, [publicStreams]);

  // useEffect(() => {
  //   roomStreamsRef.current = roomStreams;
  // }, [roomStreams]);

  userKeyPairRef.current = userKeyPair;

  roomEncryptKeyRef.current = roomEncryptKey;

  useEffect(() => {
    selectedRoomRef.current = selectedRoom;
  }, [selectedRoom]);

  roomKeyRef.current = roomKey;

  useEffect(() => {
    selectedThreadRef.current = selectedThread;
  }, [selectedThread]);

  useEffect(() => {
    messageListByRoomRef.current = messageListByRoomPrev;
  }, [messageListByRoomPrev]);

  useEffect(() => {
    roomListRef.current = rooms ?? { rooms: {}, ids: [] };
  }, [rooms]);

  useEffect(() => {
    chatUnreadCountRef.current = chatUnreadCount;
  }, [chatUnreadCount]);

  // useEffect(() => {
  //   roomCallsRef.current = roomCalls;
  // }, [roomCalls]);

  // useEffect(() => {
  //   roomCallAttendeesRef.current = roomCallAttendees;
  // }, [roomCallAttendees]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    socketReceivedDataRef.current = socketReceivedData;
  }, [socketReceivedData]);

  const navigate = useNavigate();

  const sendSocketData = ({ type, ...data }) => {
    if (socketRef.current) {
      socketRef.current.emit(type, data);
    }
  };

  const addDataToSocketReceivedData = (message) => {
    if (message) {
      const newData = [
        ...socketReceivedDataRef.current,
        { ...message, id: uuidv4() },
      ];
      setSocketReceivedData(newData);
    }
  };

  const [requestHeaders, setRequestHeaders] = useState(getRequestHeaders());

  useEffect(() => {
    const handleSocket = async () => {
      if (!userData) {
        socketRef.current = null;
      }
      const publicKey = JSON.parse(
        localStorage.getItem(LocalStorageKey.KeyPair) ?? "{}",
      ).public_key;
      if (!socketRef.current && userData && publicKey) {
        socketRef.current = connect(config.WS_API_BASE_URL, {
          extraHeaders: {
            ...getRequestHeaders(),
            "x-access-token": config.ACCESS_TOKEN,
          },
          auth: {
            "x-public-key": publicKey,
          },
          path: config.WS_API_BASE_PATH,
        });

        socketRef.current.on("connect", function () {});

        socketRef.current.on("disconnect", function () {
          dispatch(setIdsRefresh(messageListByRoomRef.current.roomIds));
        });

        socketRef.current.on(SocketEventTypeEnum.error, (message) => {
          if (message.status === HttpStatusCode.Unauthorized) {
            setRequestHeaders(getRequestHeaders());
          }
        });

        socketRef.current.on(
          SocketEventTypeEnum.removeRoom,
          (data: { roomId: string; deviceInfo: DeviceInfo }) => {
            const { roomId } = data;
            if (roomListRef.current.ids.includes(roomId)) {
              dispatch(removeRoom({ roomId, isDelete: true }));
            }

            if (messageListByRoomRef.current.roomIds.includes(roomId)) {
              dispatch(removeMessage({ roomId }));
            }
            // const roomShareKey = { ...roomEncryptKeyRef.current };
            // delete roomShareKey[roomId];
            // setRoomEncryptKeys(roomShareKey);
            // localStorage.setItem(
            //   LocalStorageKey.RoomEncryptKey,
            //   JSON.stringify(roomShareKey)
            // );

            if (selectedRoomRef.current?.id === roomId) {
              dispatch(upsertSelectedRoom({ room: undefined }));
              navigate("/");
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.memberChanged,
          (data: { roomId: string; memberList: UserRoom[] }) => {
            const { roomId, memberList } = data;

            if (
              selectedRoomRef.current &&
              selectedRoomRef.current.id === roomId
            ) {
              const updatedRoom = {
                ...selectedRoomRef.current,
                users: memberList,
              };
              dispatch(upsertRoom({ room: updatedRoom }));
              dispatch(upsertSelectedRoom({ room: updatedRoom }));
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.removeMessage,
          async (data: {
            roomId: string;
            userMessage: { threadId: string; id: string };
          }) => {
            const { roomId, userMessage } = data;
            const isExistMessages =
              messageListByRoomRef.current.roomIds.includes(roomId);
            const isExistRoom = roomListRef.current.ids.includes(roomId);
            let newMessageByRoom;

            if (!userMessage.threadId) {
              if (isExistMessages) {
                newMessageByRoom = {
                  ...messageListByRoomRef.current,
                  rooms: {
                    ...messageListByRoomRef.current.rooms,
                    [roomId]: {
                      ...messageListByRoomRef.current.rooms[roomId],
                      messages: messageListByRoomRef.current.rooms[
                        roomId
                      ].messages.filter((item) => item.id !== userMessage.id),
                    },
                  },
                };
                dispatch(setMessageListByRoom(newMessageByRoom));
                const room = roomListRef.current.rooms[roomId];

                if (
                  isExistRoom &&
                  room.latestMessageObject.id === userMessage.messageId
                ) {
                  const roomMessages = sortMessages(
                    newMessageByRoom.rooms[roomId].messages,
                  );
                  const latestMessage = roomMessages[roomMessages.length - 1];
                  let updatedRoom;
                  if (!latestMessage) {
                    updatedRoom = { ...room, latestMessageObject: null };
                  } else {
                    updatedRoom = {
                      ...room,
                      latestMessageObject: {
                        ...latestMessage,
                        id: latestMessage.messageId,
                        content:
                          latestMessage.message?.content &&
                          latestMessage.message?.content,
                        senderUser: newMessageByRoom.rooms[roomId].users.find(
                          (el) => el.userId === latestMessage.senderId,
                        ),
                      },
                    };
                  }

                  dispatch(upsertRoom({ room: updatedRoom }));
                }
              } else if (isExistRoom) {
                const oldRoom = roomListRef.current.rooms[roomId];
                if (oldRoom.latestMessageObject.id === userMessage.messageId) {
                  const room = await handleDetailInfo({ roomKey });
                  const latestMessage = parseJSON<LatestMessage>(
                    room.latestMessage ?? "{}",
                  );
                  const decryptedMessage = await handleDecryptMessageContent(
                    latestMessage,
                    room,
                  );

                  const updatedRoom = {
                    ...room,
                    latestMessageObject: decryptedMessage,
                    status: ROOM_STATUS.JOINED,
                  };
                  dispatch(upsertRoom({ room: updatedRoom }));
                }
              }
            } else {
              if (isExistMessages) {
                const updatedMessages = messageListByRoomRef.current.rooms[
                  roomId
                ].messages.map((item) => {
                  if (item.threadMessages) {
                    return {
                      ...item,
                      threadMessages: item.threadMessages.filter(
                        (thread) => thread.id !== userMessage.id,
                      ),
                    };
                  }
                  return item;
                });

                dispatch(updateMessages({ messages: updatedMessages }));
                const newThreadMessage =
                  selectedThreadRef.current.threadMessages.filter(
                    (item) => item?.id !== userMessage.id,
                  );

                const updatedMessageThread = {
                  ...selectedThreadRef.current,
                  threadMessages: newThreadMessage,
                };
                dispatch(setSelectedThread(updatedMessageThread));
              }
            }
          },
        );

        // socketRef.current.on(
        //   SocketEventTypeEnum.requestPublicKey,
        //   ({ requestorSocketId, roomId }) => {
        //     if (socketRef && socketRef.current) {
        //       socketRef.current.emit(SocketEventTypeEnum.sendPublicKey, {
        //         publicKey: JSON.parse(
        //           localStorage.getItem(LocalStorageKey.KeyPair) ?? '{}',
        //         ).public_key,
        //         senderSocketId: socketRef.current.id,
        //         receiverSocketId: requestorSocketId,
        //         senderId: userData.id,
        //         roomId,
        //       });
        //     }
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.streamStarted,
        //   (userStream: UserStream) => {
        //     if (userStream.userRoomId) {
        //       const newRoomStreams = {
        //         ...roomStreamsRef.current,
        //         [userStream.UserRoom.roomId]: (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).filter((item) => item.id !== userStream.id),
        //       };
        //       if (userStream.isActive) {
        //         newRoomStreams[userStream.UserRoom.roomId] = (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).concat(userStream);
        //       }
        //       setRoomStreams(newRoomStreams);
        //     } else {
        //       setPublicStreams({
        //         ...publicStreamsRef.current,
        //         [userStream.userId]: userStream,
        //       });
        //     }
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.streamEnded,
        //   (userStream: UserStream) => {
        //     if (userStream.userRoomId) {
        //       setRoomStreams({
        //         ...roomStreamsRef.current,
        //         [userStream.UserRoom.roomId]: (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).filter((item) => item.id !== userStream.id),
        //       });
        //     } else {
        //       const newPublicStreams = { ...publicStreamsRef.current };
        //       delete newPublicStreams[userStream.userId];
        //       setPublicStreams(newPublicStreams);
        //     }
        //   }
        // );

        socketRef.current.on(
          SocketEventTypeEnum.response_ComputeRoomKey,
          async (data: ComputeRoomKeyRequest) => {
            try {
              const newNextMembemPublicKeys =
                data.nextMemberPublicKeys.slice(1);
              let result;
              if (data.curve_params) {
                // console.log("compute room key with curve params", data.roomId);
                result = await ChatAxios.postReq("/compute", {
                  private_key: userKeyPairRef.current?.private_key,
                  curve_params: data.curve_params,
                });
              } else {
                // console.log(
                //   "compute room key without curve params",
                //   data.roomId
                // );

                result = await ChatAxios.postReq("/compute", {
                  private_key: userKeyPairRef.current?.private_key,
                  public_key: data.ownerPublicKey,
                });
              }

              if (newNextMembemPublicKeys.length === 0) {
                // console.log("handle send done shared key", data.roomId);
                socketRef.current?.emit(
                  SocketEventTypeEnum.request_ComputeRoomKey_Done,
                  {
                    ...data,
                    curve_params: result.data,
                  },
                );
              } else {
                // console.log("handle send result compute", data.roomId);
                socketRef.current?.emit(
                  SocketEventTypeEnum.request_ComputeRoomKey,
                  {
                    ...data,
                    nextMemberPublicKeys: newNextMembemPublicKeys,
                    curve_params: result.data,
                  } as ComputeRoomKeyRequest,
                );
              }
            } catch (error) {
              console.error(getErrorMessage(error), error);
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.response_ComputeRoomKey_Done,
          async (data: ComputeRoomKeyRequest) => {
            try {
              // console.log("compute room key with curve params", data.roomId);
              const result = await ChatAxios.postReq("/compute", {
                private_key: userKeyPairRef.current?.private_key,
                curve_params: data.curve_params,
              });
              // console.log("handle done get share key room", data.roomId);
              handleDoneGetShareKeyRoom(
                result.data,
                data.userPublicKeyList,
                data.roomKeyExchangeId,
                data.roomId,
                data.isOwner,
              );
            } catch (error) {
              console.error(getErrorMessage(error), error);
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.newRoom,
          async (newRoom: Room) => {
            const latestMessageObject = parseJSON<LatestMessage>(
              newRoom.latestMessage,
            );
            const decryptedMessage = await handleDecryptMessageContent(
              latestMessageObject,
              newRoom,
            );
            const updatedRoom = {
              ...newRoom,
              latestMessageObject: decryptedMessage,
              status: ROOM_STATUS.JOINED,
            };
            if (
              searchValueRef.current === "" ||
              roomListRef.current.ids.includes(newRoom.id)
            ) {
              dispatch(
                upsertRoom({
                  room: updatedRoom,
                }),
              );
            }

            if (newRoom.encryptType === "key_exchange") {
              try {
                const roomNeedExchangeKey =
                  await handleGetOneRoomNeedExchangeKey(newRoom.id);

                refReadyForPreprocessing.current[newRoom.id] = {
                  room: roomNeedExchangeKey,
                  isProcessing: false,
                  id: newRoom.id,
                };

                setRoomKeyExchangeStatus((prevState) => {
                  return {
                    ...prevState,
                    [newRoom.id]: {
                      ...prevState[newRoom.id],
                      status: "checking_key_exchange_status",
                    },
                  };
                });
              } catch (error) {
                console.error("Cannot get room need to exchange key", error);
                // console.log("Cannot get room need to exchange key", error);
              }
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.userRoomChanged,
          (newUserRoom) => {
            if (
              selectedRoomRef.current &&
              selectedRoomRef.current.id === newUserRoom.id
            ) {
              dispatch(
                setSelectedRoom({
                  ...selectedRoomRef.current,
                  users: selectedRoomRef.current.users.map((item) =>
                    item.userId === newUserRoom.userId ? newUserRoom : item,
                  ),
                }),
              );
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.messageAdded,
          async (newMessage: UserMessage) => {
            const { roomId, additionalData, threadId, senderId, message } =
              newMessage;

            const roomInfo = roomListRef.current.rooms[roomId];
            const messageListByRoommInfo =
              messageListByRoomRef.current.rooms[roomId];
            try {
              let decryptedMessage;
              if (!roomInfo || !roomInfo.users) {
                if (searchValueRef.current === "") {
                  const detailInfo = await handleDetailInfo({ roomId });
                  const latestMessage = parseJSON<LatestMessage>(
                    detailInfo.latestMessage ?? "{}",
                  );
                  decryptedMessage = await handleDecryptMessageContent(
                    latestMessage,
                    detailInfo,
                  );
                  const updatedRoom = {
                    ...detailInfo,
                    latestMessageObject: decryptedMessage,
                    status: ROOM_STATUS.JOINED,
                  };
                  dispatch(upsertRoom({ room: updatedRoom }));
                }
              } else if (!threadId) {
                decryptedMessage = await handleDecryptMessageContent(
                  message,
                  roomInfo,
                );
                const updatedRoom = {
                  ...roomInfo,
                  latestMessageObject: {
                    ...decryptedMessage,
                    senderUser: roomInfo.users.find(
                      (item) => item.userId === senderId,
                    ),
                  },
                };
                dispatch(upsertRoom({ room: updatedRoom }));
              }
              if (messageListByRoommInfo) {
                decryptedMessage = await handleDecryptMessageContent(
                  message,
                  messageListByRoomRef.current.rooms[roomId],
                );
                const { messages, ...rest } =
                  messageListByRoomRef.current.rooms[roomId];

                let updatedMessages = messages;

                if (threadId) {
                  updatedMessages = messages.map((item) =>
                    item.id === threadId
                      ? {
                          ...item,
                          threadMessages: mergeArrays(
                            "id",
                            item.threadMessages ?? [],
                            [
                              {
                                ...newMessage,
                                message: {
                                  ...decryptedMessage,
                                  isDecrypt: true,
                                },
                              },
                            ],
                          ),
                        }
                      : item,
                  );

                  if (selectedThreadRef.current?.id === threadId) {
                    const updatedThread = {
                      ...selectedThreadRef.current,
                      threadMessages: mergeArrays(
                        "id",
                        [
                          {
                            ...newMessage,
                            message: { ...decryptedMessage, isDecrypt: true },
                          },
                        ],
                        selectedThreadRef.current.threadMessages || [],
                      ),
                    };
                    selectedThreadRef.current = updatedThread;
                    dispatch(setSelectedThread(updatedThread));
                  }
                } else {
                  updatedMessages = mergeArrays("id", messages, [
                    {
                      ...newMessage,
                      message: { ...decryptedMessage, isDecrypt: true },
                    },
                  ]);
                }

                const updatedRoomData = {
                  ...messageListByRoomRef.current,
                  rooms: {
                    ...messageListByRoomRef.current.rooms,
                    [roomId]: {
                      ...messageListByRoomRef.current.rooms[roomId],
                      messages: updatedMessages,
                    },
                  },
                };

                messageListByRoomRef.current = updatedRoomData;
                dispatch(setMessageListByRoom(updatedRoomData));
              }
            } finally {
              if (
                selectedRoomRef.current?.id !== roomId &&
                senderId !== userData.id
              ) {
                const unreadCount =
                  (chatUnreadCountRef.current[roomId] ?? 0) + 1;
                setLocalChatUnreadCount(roomId, unreadCount);
                dispatch(setChatUnreadCount({ roomId, count: unreadCount }));
              }

              dispatch(handleRemoveSendingMessage(additionalData.messageId));
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.response_RestartKeyExchange,
          async (data: Room) => {
            refReadyForPreprocessing.current[data.id] = {
              room: data,
              isProcessing: false,
              id: data.id,
            };

            setRoomKeyExchangeStatus((prevState) => {
              return {
                ...prevState,
                [data.id]: {
                  ...prevState[data.id],
                  status: "checking_key_exchange_status",
                },
              };
            });
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.offer,
          addDataToSocketReceivedData,
        );

        socketRef.current.on(
          SocketEventTypeEnum.answer,
          addDataToSocketReceivedData,
        );

        socketRef.current.on(
          SocketEventTypeEnum.candidate,
          addDataToSocketReceivedData,
        );

        socketRef.current.on("connect_error", (err) => {
          // console.log("connect_error", JSON.stringify(err));
        });

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_RoomMember_Online,
        //   (data: { roomId: string; deviceInfo: ConnectedDevice }) => {
        //     handleAddOnlineMember(data.roomId, data.deviceInfo);
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_RoomMember_Offline,
        //   (data: { roomId: string; user }) => {
        //     handleRemoveOnlineMember(data.roomId);
        //   },
        // );

        socketRef.current.on(
          SocketEventTypeEnum.roomCallStarted,
          ({
            roomCall,
            roomId,
          }: {
            roomCall: RoomCall;
            roomId: string;
            deviCeInfo: { userId: string };
          }) => {
            setRoomCalls({
              ...roomCallsRef.current,
              [roomId]: roomCall,
            });
            setRoomCallAttendees({
              ...roomCallAttendeesRef.current,
              [roomCall.id]: [],
            });

            setIdRoomCall(roomId);
          },
        );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallEnded,
        //   (roomCall: RoomCall) => {
        //     const newRoomCalls = { ...roomCallsRef.current };
        //     if (newRoomCalls[roomCall.roomId]) {
        //       delete newRoomCalls[roomCall.roomId];
        //       setRoomCalls(newRoomCalls);
        //     }

        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };
        //     delete newRoomCallAttendees[roomCall.id];
        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallReceiveJoinRequest,
        //   ({
        //     roomCall,
        //     joinUserRoomCallStatus,
        //   }: {
        //     roomCall: RoomCall;
        //     joinUserRoomCallStatus: UserRoomCallStatus;
        //   }) => {
        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };

        //     newRoomCallAttendees[roomCall.id] = mergeArrays(
        //       "id",
        //       [joinUserRoomCallStatus],
        //       newRoomCallAttendees[roomCall.id] ?? []
        //     );
        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallReceiveLeaveRequest,
        //   ({
        //     roomCall,
        //     leaveUserRoomCallStatus,
        //   }: {
        //     roomCall: RoomCall;
        //     leaveUserRoomCallStatus: UserRoomCallStatus;
        //   }) => {
        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };
        //     newRoomCallAttendees[roomCall.id] = (
        //       newRoomCallAttendees[roomCall.id] ?? []
        //     ).filter((item) => item.id !== leaveUserRoomCallStatus.id);

        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );
      }
    };
    handleSocket();
  }, [userData, requestHeaders]);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.emit(SocketEventTypeEnum.roomCallDisconnectedAll);
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <ConnectionContext.Provider
      value={{
        socketRef,
        setSocketReceivedData,
        socketReceivedData,
        sendSocketData,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnectionContext = () => {
  return useContext(ConnectionContext);
};
