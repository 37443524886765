//take all the slices that we are creating (combined all the reducers)
import { combineReducers, Reducer } from "redux";
import storage from "redux-persist/lib/storage";
import appReducer from "./slices/app";
import chatReducer from "./slices/chat";
import imageReducer from "./slices/image";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import otpReducer from "./slices/otp";
import settingReducer from "./slices/setting";
import { createTransform, persistReducer } from "redux-persist";
import { MessageState, ChatState, AuthState } from "./types";
import { initialState as messageInitialState } from "./slices/message";
import { initialState as chatInitialState } from "./slices/chat";
import { initialState as authInitialState } from "./slices/auth";
//slices

//create root configuration (how data store and how read out data from store)
const saveMessage = createTransform(
  (inboundState: MessageState) => ({
    ...messageInitialState,
    messageListByRoom: inboundState.messageListByRoom,
    totalMessagesByRoom: inboundState.totalMessagesByRoom,
    idsRefresh: inboundState.idsRefresh,
  }),
  (outboundState) => outboundState,
  { whitelist: ["message"] }
);

const saveChat = createTransform(
  (inboundState: ChatState) => ({
    ...chatInitialState,
    rooms: inboundState.rooms,
    roomsSearch: inboundState.roomsSearch,
  }),
  (outboundState) => outboundState,
  { whitelist: ["chat"] }
);

const saveAuth = createTransform(
  (inboundState: AuthState) => ({
    ...authInitialState,
    userData: inboundState.userData,
    chatUserData: inboundState.chatUserData,
  }),
  (outboundState) => outboundState,
  { whitelist: ["auth"] }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["message", "chat", "auth", "setting"],
  transforms: [saveMessage, saveChat, saveAuth],
};

//create combine reducer
const rootReducer: Reducer = combineReducers({
  app: appReducer,
  chat: chatReducer,
  image: imageReducer,
  auth: authReducer,
  message: messageReducer,
  otp: otpReducer,
  setting: settingReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
