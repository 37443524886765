import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthState } from "../types";

export const selectAuthState = (state: RootState): AuthState => state.auth;

export const selectFcmToken = createSelector(
  [selectAuthState],
  (auth) => auth.fcmToken,
);

export const selectUserData = createSelector(
  [selectAuthState],
  (auth) => auth.userData,
);

export const selectLoading = createSelector(
  [selectAuthState],
  (auth) => auth.loading,
);

export const selectLoadingLogin = createSelector(
  [selectAuthState],
  (auth) => auth.loadingLogin,
);

export const selectChatUserData = createSelector(
  [selectAuthState],
  (auth) => auth.chatUserData,
);

export const selectIsPasswordSet = createSelector(
  [selectAuthState],
  (auth) => auth.isPasswordSet,
);

export const selectUserDeviceInfo = createSelector(
  [selectAuthState],
  (auth) => auth.userDeviceInfo,
);
