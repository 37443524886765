import {
  handleDecryptMessageWithKey,
  handleDecryptMessageWithType,
  handleEncryptMessageWithKey,
} from "../services/api";

import { Message, MessageTypeEnum, Room, RoomEncryptType } from "../services";

import { RoomEncryptTypeEnum } from "../services";
import { handleEncryptMessageWithType } from "../services/api";
import {
  addLocalStorageDecryptedMessage,
  getDecryptedUserMessageByUserMessageId,
  getOneLocalStorageRoomEncryptKey,
} from "./local-storage";
import { ENCRYPT_TYPE } from "../constant/room";

export const handleEncryptMessage = async ({
  message,
  roomEncryptType,
  key,
}: {
  message: string;
  roomEncryptType: RoomEncryptType;
  key?: string;
}) => {
  if (!message) return message;
  switch (roomEncryptType) {
    case RoomEncryptTypeEnum.aes_256_cbc:
      if (!roomEncryptType) throw new Error("missing room encrypt type");
      return await handleEncryptMessageWithType(message, roomEncryptType);
    case RoomEncryptTypeEnum.key_exchange:
      if (!key) throw new Error("missing encrypt key");
      return await handleEncryptMessageWithKey({ message, key });
    default:
      return message;
  }
};

export const handleDecryptMessage = async ({
  message,
  roomEncryptType,
  key,
}: {
  message: string;
  roomEncryptType: RoomEncryptType;
  key?: string;
}) => {
  if (!message) return message;

  let result: string;
  switch (roomEncryptType) {
    case RoomEncryptTypeEnum.aes_256_cbc:
      if (!roomEncryptType) throw new Error("missing room encrypt type");
      result = await handleDecryptMessageWithType(message, roomEncryptType);

      return result;
    case RoomEncryptTypeEnum.key_exchange:
      if (!key) throw new Error("missing encrypt key");
      return await handleDecryptMessageWithKey({ message, key });
    default:
      return message;
  }
};

export const handleDecryptMessageContent: (
  message: Message,
  room: Room,
) => Promise<Message> = async (message, room) => {
  if (!message) return message;
  const decryptedMessage = getDecryptedUserMessageByUserMessageId(
    room?.id,
    message?.id,
  );
  if (decryptedMessage)
    return {
      ...message,
      content: decryptedMessage.content,
      messageMeta: decryptedMessage.messageMeta,
    };
  const { messageMeta, content } = message;
  let decryptContent = content;
  let decryptMessageMeta = messageMeta;
  const roomEncryptKey = getOneLocalStorageRoomEncryptKey(room?.id);
  try {
    if (roomEncryptKey || room?.encryptType === ENCRYPT_TYPE.AES_256_CBC) {
      switch (message.messageType) {
        case MessageTypeEnum.file:
          decryptMessageMeta = await handleDecryptMessage({
            message: messageMeta,
            roomEncryptType: room.encryptType,
            key: roomEncryptKey,
          });
          break;
        default:
          decryptContent = await handleDecryptMessage({
            message: content,
            roomEncryptType: room.encryptType,
            key: roomEncryptKey,
          });
          break;
      }
    }
    addLocalStorageDecryptedMessage(room?.id, message?.id, {
      content: decryptContent,
      messageMeta: decryptMessageMeta,
    });
  } catch (error) {
    console.error("Error decrypt message", error);
  }

  return {
    ...message,
    content: decryptContent,
    messageMeta: decryptMessageMeta,
  };
};
