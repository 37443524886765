import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DISPLAY_TYPE, ROOM_STATUS } from "../../constant/room";
import { Room } from "../../services";
import { ChatState, RoomState } from "../types";

// define initial state
export const initialState: ChatState = {
  rooms: { rooms: {}, ids: [] },
  roomsSearch: { rooms: {}, ids: [] },
  isLoading: false,
  total: 0,
  search: "",
  selectedRoom: undefined,
  selectedRoomMembers: {},
  selectedRoomCreatorUser: undefined,
  chatUnreadCount: {},
  searchValue: "",
  isLoadMore: true,
};

// create chatSlice
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    //Toggle sidebar
    setChatUnreadCount(state, action) {
      state.chatUnreadCount[action.payload.roomId] = action.payload.count;
    },
    setAllChatUnreadCount(state, action) {
      state.chatUnreadCount = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },

    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setRoomsSearch: (state, action) => {
      state.roomsSearch = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSelectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setSelectedRoomMembers: (state, action) => {
      state.selectedRoomMembers = action.payload;
    },
    setSelectedRoomCreatorUser: (state, action) => {
      state.selectedRoomCreatorUser = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsLoadMore: (state, action) => {
      state.isLoadMore = action.payload;
    },
    getRoomList: (state, action) => {},
    upsertRoom: (state, action: PayloadAction<{ room: Room }>) => {
      const { room } = action.payload;

      state.rooms = {
        ...state.rooms,
        rooms: {
          ...state.rooms.rooms,
          [room.id]: room,
        },
        ids: state.rooms.ids.includes(room.id)
          ? state.rooms.ids
          : [...state.rooms.ids, room.id],
      };

      state.roomsSearch.rooms[state.searchValue] = state.rooms;
    },
    removeRoom: (
      state,
      action: PayloadAction<{ roomId: string; isDelete?: boolean }>
    ) => {
      const { roomId, isDelete } = action.payload;

      const isSearchEmpty = state.searchValue === "";
      const room = state.rooms.rooms[roomId];

      if (
        isSearchEmpty ||
        room.displayType === DISPLAY_TYPE.PRIVATE ||
        isDelete
      ) {
        if (state.rooms.ids.includes(roomId) && room) {
          delete state.rooms.rooms[roomId];
          state.rooms.ids = state.rooms.ids.filter((id) => id !== roomId);
          state.total -= 1;
        }

        Object.keys(state.roomsSearch.rooms).forEach((key) => {
          const searchRoom = state.roomsSearch.rooms[key];
          if (searchRoom.rooms[roomId]) {
            delete searchRoom.rooms[roomId];
            searchRoom.ids = searchRoom.ids.filter((id) => id !== roomId);
          }
        });
      } else {
        if (state.rooms.ids.includes(roomId) && room) {
          room.status = ROOM_STATUS.LEFT;
        }
        Object.keys(state.roomsSearch.rooms).forEach((key) => {
          const searchRoom = state.roomsSearch.rooms[key];
          if (searchRoom.rooms[roomId]) {
            searchRoom.rooms[roomId].status = ROOM_STATUS.LEFT;
          }
        });
      }
    },
    getRoomDetail: (state, action) => {},
    changeSelectedRoom: (state, action) => {},
    upsertSelectedRoom: (
      state,
      action: PayloadAction<{ room: Room | undefined }>
    ) => {
      const { room } = action.payload;
      state.selectedRoom = room;
      if (!room) {
        state.selectedRoomCreatorUser = undefined;
        state.selectedRoomMembers = {};
        return;
      }
      const users = room.users ?? [];
      const creator = users?.find((item) => item.isCreator);
      state.selectedRoomCreatorUser = creator;
      const usersRoom = users?.reduce((pre, curr) => {
        pre[curr.userId] = curr;
        return pre;
      }, {});
      state.selectedRoomMembers = usersRoom;
    },
    setSelectedRoomCached: (
      state,
      action: PayloadAction<{ roomKey?: string; roomId?: string }>
    ) => {
      const { roomKey, roomId } = action.payload;
      if (roomId) {
        if (state.rooms.rooms[roomId].status === ROOM_STATUS.JOINED) {
          state.selectedRoom = state.rooms.rooms[roomId];
        }
      } else if (roomKey) {
        const idFinded = state.rooms.ids.find(
          (id) => state.rooms.rooms[id].key === roomKey
        );
        if (
          idFinded &&
          state.rooms.rooms[idFinded].status === ROOM_STATUS.JOINED
        ) {
          state.selectedRoom = state.rooms.rooms[idFinded];
        }
      } else {
        state.selectedRoom = undefined;
      }
    },
    upsertRoomsSearch: (
      state,
      action: PayloadAction<{ rooms: RoomState; id: string }>
    ) => {
      const { rooms, id } = action.payload;

      state.roomsSearch = {
        ...state.roomsSearch,
        rooms: {
          ...state.roomsSearch.rooms,
          [id]: rooms,
        },
        ids: state.roomsSearch.ids.includes(id)
          ? state.roomsSearch.ids
          : [...state.roomsSearch.ids, id],
      };
    },
    deleteMember: (state, action) => {},
    leaveRoom: (state, action) => {},
    deleteRoom: (state, action) => {},
    createRoom: (state, action) => {},
    addMember: (state, action) => {},
    updateRoomName: (state, action) => {},
    updateRoomAvatar: (state, action) => {},
    updateJoinRequest: (state, action) => {},
    fetchRequestList: (state, action) => {},
    changeNickName: (state, action) => {},
    openRoom: (state, action) => {},
    joinRoom: (state, action) => {},
    clearChat: () => initialState,
  },
});

// export reducer
export default chatSlice.reducer;

// export actions
export const {
  setChatUnreadCount,
  setAllChatUnreadCount,
  setSearchValue,
  setIsLoading,
  setRooms,
  setSearch,
  setSelectedRoom,
  setSelectedRoomCreatorUser,
  setSelectedRoomMembers,
  setTotal,
  getRoomList,
  upsertRoom,
  removeRoom,
  getRoomDetail,
  upsertSelectedRoom,
  setSelectedRoomCached,
  upsertRoomsSearch,
  clearChat,
  setIsLoadMore,
  deleteMember,
  leaveRoom,
  deleteRoom,
  changeSelectedRoom,
  createRoom,
  addMember,
  updateRoomName,
  updateRoomAvatar,
  updateJoinRequest,
  fetchRequestList,
  changeNickName,
  openRoom,
  joinRoom,
} = chatSlice.actions;
