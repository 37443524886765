import { all } from "redux-saga/effects";
import { watchAuth } from "./sagas/auth";
import { watchChat } from "./sagas/chat";
import { watchOTP } from "./sagas/otp";
import { watchMessage } from "./sagas/message";
import { watchSetting } from "./sagas/setting";

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchChat(),
    watchOTP(),
    watchMessage(),
    watchSetting(),
  ]);
}
