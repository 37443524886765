import { DialogContent } from "@mui/material";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { INPUT_OTP_TYPE } from "../../constant/room";
import { checkIsPasswordSet } from "../../redux/slices/auth";
import { onUpdateOTPTimeInfo } from "../../redux/slices/otp";
import { useAppDispatch } from "../../redux/store";
import { handleResendOTP } from "../../services/api";
import { getErrorMessage } from "../../utils/error";
import InputOTP from "../input-otp/InputOTP";

export default function ConfirmOTPAndSetNewPassword({
  handlePrevStep,
  newPassword,
}: {
  handlePrevStep: () => void;
  newPassword: string;
}) {
  const dispatch = useAppDispatch();

  const onResendOTP = useCallback(async () => {
    const result = await handleResendOTP();

    dispatch(
      onUpdateOTPTimeInfo({
        type: INPUT_OTP_TYPE.EXTERNAL_SET_PASSWORD,
        otpInfo: result.data,
      }),
    );
  }, []);

  const handleSuccess = useCallback(async () => {
    try {
      const result = await dispatch(checkIsPasswordSet());

      if (result) {
        toast.success("Password has been set successfully");
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }, []);

  return (
    <DialogContent>
      <InputOTP
        type={INPUT_OTP_TYPE.EXTERNAL_SET_PASSWORD}
        handleResendOTP={onResendOTP}
        handleSuccess={handleSuccess}
        formValues={newPassword}
        handleBack={handlePrevStep}
      />
    </DialogContent>
  );
}
