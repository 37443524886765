import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputOTPType } from "../../constant/profile";
import { OTPTimeInfo } from "../../contexts";

interface InitialStateType {
  otpTimeInfo: Partial<Record<InputOTPType, OTPTimeInfo | undefined>>;
}

const initialState: InitialStateType = {
  otpTimeInfo: {},
};

const otpSlice = createSlice({
  initialState,
  name: "otp",
  reducers: {
    setOtpTimeInfo(state, action) {
      state.otpTimeInfo = action.payload;
    },
    onUpdateOTPTimeInfo(
      state,
      action: PayloadAction<{ type: InputOTPType; otpInfo: OTPTimeInfo }>,
    ) {},
  },
});

export const { setOtpTimeInfo, onUpdateOTPTimeInfo } = otpSlice.actions;

export default otpSlice.reducer;
