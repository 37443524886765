import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { INPUT_OTP_TYPE } from "../../constant/room";
import { onUpdateOTPTimeInfo } from "../../redux/slices/otp";
import { useAppDispatch } from "../../redux/store";
import { handleRequestPassword } from "../../services/api";
import { getErrorMessage } from "../../utils/error";

export default function RequestPassword({
  newPassword,
  setNewPassword,
  handleNextStep,
}: {
  newPassword: string;
  setNewPassword: (newPassword: string) => void;
  handleNextStep: () => void;
}) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onRequestPassword = useCallback(async () => {
    try {
      setLoading(true);
      const result = await handleRequestPassword();
      if (result) {
        dispatch(
          onUpdateOTPTimeInfo({
            type: INPUT_OTP_TYPE.EXTERNAL_SET_PASSWORD,
            otpInfo: result.data,
          }),
        );
      }
      handleNextStep();
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <Typography>Set your password to continue</Typography>
        <TextField
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type="button" disabled={loading} onClick={onRequestPassword}>
          {loading ? <CircularProgress size={25} /> : "Confirm"}
        </Button>
      </DialogActions>
    </>
  );
}
