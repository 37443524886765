import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingWrapper from "./components/LoadingWrapper";
import AppNotification from "./components/app-notification/AppNotification";
import ThemeSettings from "./components/settings";
import { ROOM_STATUS } from "./constant/room";
import OTPContextProvider from "./contexts/OTPContext";
import ConfirmDialogProvider from "./contexts/confirm-dialog";
import { LocalStorageKey } from "./enums";
import { selectLoading } from "./redux/selectors/auth";
import { selectSearchValue, selectSelectedRoom } from "./redux/selectors/chat";
import {
  selectIdsRefresh,
  selectMessageListByRoom,
} from "./redux/selectors/message";
import { clearAuth, getUserInfo } from "./redux/slices/auth";
import {
  clearChat,
  getRoomList,
  removeRoom,
  setAllChatUnreadCount,
  setIsLoading,
  setRooms,
  setSearch,
  setSearchValue,
  setTotal,
  upsertSelectedRoom,
} from "./redux/slices/chat";
import {
  clearMessage,
  getRoomMessageLatest,
  removeMessage,
  setIdsRefresh,
  setSelectedThread,
} from "./redux/slices/message";
import { getConfigSettings } from "./redux/slices/setting";
import { useAppDispatch, useAppSelector } from "./redux/store";
import Router from "./routes";
import { handleGetRoomInfo } from "./services/api";
import ThemeProvider from "./theme";
import { CookiesKeys, getCookie } from "./utils/cookie";
import { getErrorMessage } from "./utils/error";
import { getAllLocalChatUnreadCount } from "./utils/local-storage";
import { sortMessages } from "./utils/sort";

dayjs.extend(relativeTime);

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a sec",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mth",
    MM: "%d mths",
    y: "a yr",
    yy: "%d yrs",
  },
});

function App() {
  const [isPopup, setIsPopup] = useState(true);
  const isChecked =
    JSON.parse(localStorage.getItem(LocalStorageKey.Checked)) ?? null;
  const auth_data = getCookie(CookiesKeys.auth_data);

  const handleDismiss = useCallback(() => {
    setIsPopup(false);
  }, []);

  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const selectedRoom = useAppSelector(selectSelectedRoom);
  const idsRefresh = useAppSelector(selectIdsRefresh);
  const messageListByRoom = useAppSelector(selectMessageListByRoom);
  const searchValue = useAppSelector(selectSearchValue);
  const navigate = useNavigate();

  useEffect(() => {
    batch(() => {
      dispatch(setAllChatUnreadCount(getAllLocalChatUnreadCount()));
      dispatch(setSearchValue(""));
      dispatch(getUserInfo());
      dispatch(setIdsRefresh(messageListByRoom.roomIds));
      dispatch(getConfigSettings());
    });

    return () => {
      batch(() => {
        dispatch(setRooms({ rooms: {}, ids: [] }));
        dispatch(setIsLoading(true));
        dispatch(setTotal(0));
        dispatch(setSearch(""));
      });
    };
  }, []);

  useEffect(() => {
    dispatch(setSelectedThread(undefined));
  }, [selectedRoom?.id]);

  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
    };

    const handleOnline = async () => {
      if (isOffline) {
        if (selectedRoom?.id) {
          try {
            const roomInfo = await handleGetRoomInfo(selectedRoom.key);
            if (roomInfo.joinStatus === ROOM_STATUS.JOINED) {
              const roomMessages = sortMessages(
                messageListByRoom.rooms[selectedRoom.id].messages,
              );
              dispatch(
                getRoomMessageLatest({
                  latestUserMessageId: roomMessages[0]?.id,
                  room: selectedRoom,
                  params: { offset: 0 },
                }),
              );
              const newIds = idsRefresh.filter((id) => id !== selectedRoom.id);
              dispatch(setIdsRefresh(newIds));
            } else {
              dispatch(upsertSelectedRoom({ room: undefined }));
              dispatch(removeRoom({ roomId: selectedRoom.id }));
              dispatch(removeMessage({ roomId: selectedRoom.id }));
              navigate("/app");
            }
            dispatch(
              getRoomList({
                search: searchValue,
                param: { limit: 10, offset: 0 },
              }),
            );
          } catch (error) {
            toast.error(getErrorMessage(error));
            dispatch(upsertSelectedRoom({ room: undefined }));
            dispatch(removeRoom({ roomId: selectedRoom.id }));
            dispatch(removeMessage({ roomId: selectedRoom.id }));
            navigate("/app");
          }
        }
      }
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [isOffline]);

  useEffect(() => {
    if (!auth_data) {
      dispatch(clearAuth());
      dispatch(clearChat());
      dispatch(clearMessage());
      localStorage.removeItem(LocalStorageKey.PersistRoot);
    }
  }, [auth_data]);

  return (
    <>
      <LoadingWrapper isLoading={loading}>
        <ThemeProvider>
          <ThemeSettings>
            <ConfirmDialogProvider>
              <OTPContextProvider>
                <Router />
                {!isChecked && isPopup && (
                  <AppNotification onDismiss={handleDismiss} />
                )}
              </OTPContextProvider>
            </ConfirmDialogProvider>
          </ThemeSettings>
        </ThemeProvider>
      </LoadingWrapper>
      <ToastContainer position="bottom-left" />
    </>
  );
}

export default App;
