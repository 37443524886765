import DOMPurify from "dompurify";
import { UserProfileInfo } from "../components/create-room-dialog/types";
import { REGEX_EMAIL, TAG_REGEX } from "../constant/room";
import { ChatUserInfo, UserInfo } from "../contexts";
import { Image, Room, UserRoom } from "../services";
import { RoomInfo } from "../types/room";

const DEFAULT_AVATAR = "/assets/Images/default_image.png";

export const getUserProfileInfoDisplayString = (user: UserProfileInfo) => {
  return (user.phone_number ?? user.email ?? "").split("|").pop();
};
export const getChatUserInfoDisplayString = (user: ChatUserInfo) => {
  return (user?.username ?? user?.email ?? "").split("|").pop();
};

export const getUserAvatar = (data: UserInfo | Image) => {
  return (
    (data as UserInfo)?.user_profile_info?.avatar ||
    (data as Image)?.senderInfo?.User.avatar ||
    DEFAULT_AVATAR
  );
};

export const getUserRoomAvatar = (data: UserRoom) => {
  return data?.User?.avatar || DEFAULT_AVATAR;
};

export const getRoomAvatar = (data: Room | RoomInfo) => {
  return data?.avatar || DEFAULT_AVATAR;
};

export const formatMessageWithLinks = (message) => {
  const urlRegex = /(https?:\/\/[^\s<]+)|(www\.[^\s<]+)/g;

  const matches = message.match(urlRegex);

  if (!matches) return message;

  return message.replace(urlRegex, (url) => {
    const href = url.startsWith("http") ? url : `https://${url}`;
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

export const isLink = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/;
  return urlRegex.test(text);
};

export const createMarkup = (html: string | Node) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export const extractContentFromHTML = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  const textContent = div.textContent || div.innerText;

  return textContent?.trim().length > 0 ? textContent.trim() : null;
};

export const findUsernameTagged = (message) => {
  const tags = [...(message ?? "").matchAll(TAG_REGEX)];
  return (tags ?? []).map((tag) => tag[1]);
};

export const getRoomKeyToLink = (url: string) => {
  const result = url.match(/\/app\/(.*)/)?.[1];
  return result;
};

export const prefixEmail = (email: string) => {
  const result = email.replace(REGEX_EMAIL, "");
  return result;
};

export const generateTagRegex = (usernames: string[]) => {
  return new RegExp(`@(${usernames?.join("|")})(?=\\s|$)`, "gi");
};
