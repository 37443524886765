import {
  RoomConnectionType,
  RoomDisplayType,
  RoomEncryptType,
  RoomEncryptTypeEnum,
  UserRoomStatusType,
} from "../services";
import { FieldValueTypeEnum } from "./term-service-room";

export const ROOM_DISPLAY_TYPE: {
  value: RoomDisplayType;
  title: string;
  description: string;
}[] = [
  {
    value: "private",
    title: "Private",
    description: "Only people with invitation could join",
  },
  {
    value: "normal",
    title: "Normal",
    description:
      "Only people with invitation or acceptance from owner could join",
  },
  { value: "public", title: "Public", description: "Any one can join" },
];

export const ROOM_CONNECTION_TYPE: {
  value: RoomConnectionType;
  title: string;
  description: string;
}[] = [
  {
    value: "server",
    title: "Server",
    description: "Message distributes through servers",
  },
  {
    value: "p2p",
    title: "P2P",
    description: "Message distributes through peer to peer technology",
  },
];

export const ROOM_ENCRYPT_TYPE: {
  value: RoomEncryptType;
  title: string;
  description: string;
}[] = [
  {
    value: RoomEncryptTypeEnum.none,
    title: "None",
    description: "No encryption",
  },
  {
    value: RoomEncryptTypeEnum.aes_256_cbc,
    title: "Basic Encryption",
    description: "Basic Encryption using AES 256 CBC",
  },
  {
    value: RoomEncryptTypeEnum.key_exchange,
    title: "End-to-end encryption",
    description:
      "End to end encryption using key exchange between member devices",
  },
];

export const ROOM_STATUS = {
  JOINED: "joined",
  REQUESTED: "requested",
  REJECTED: "rejected",
  REMOVED: "removed",
  BANNED: "banned",
  LEFT: "left",
} as const;

export const DISPLAY_TYPE = {
  PRIVATE: "private",
  NORMAL: "normal",
  PUBLIC: "public",
} as const;

export const ROOM_MESSAGE_TYPE = {
  NORMAL: "normal",
  NON_MANY: "non_many",
} as const;

export const CONNECTION_TYPE = {
  SERVER: "server",
  P2P: "p2p",
} as const;

export const ENCRYPT_TYPE = {
  NONE: "none",
  AES_256_CBC: "aes_256_cbc",
  KEY_EXCHANGE: "key_exchange",
} as const;

export const MAX_WIDTH_SIDEBAR = 400;

export const SIDEBAR_TYPE = {
  CONTACT: "CONTACT",
  STARRED: "STARRED",
  SHARED: "SHARED",
};

export const PATH_INDEX = [0, 2, 3];
export const MENU_PATH_INDEX = [0, 1, 2];

export const TYPE = {
  MSG: "msg",
  DIVIDER: "divider",
};

export const SUBTYPE = {
  TEXT: "text",
  IMG: "img",
  DOC: "doc",
  LINK: "link",
  REPLY: "reply",
  HTML: "html",
} as const;

export const MESSAGE_TYPE = {
  FILE: "file",
  HTML: "html",
  TEXT: "text",
};

export const INPUT_OTP_TYPE = {
  CHANGE_USERNAME: "change-username",
  CHANGE_PHONE: "change-phone",
  REGISTER: "register",
  CHANGE_PASSWORD: "change-password",
  CHANGE_EMAIL: "change-email",
  EXTERNAL_SET_PASSWORD: "external-set-password",
} as const;

export const PASSWORD_STEP = {
  REQUEST_PASSWORD: "request-password",
  SET_PASSWORD: "set-password",
} as const;

export const TOOLBAR_OPTIONS = {
  INLINE: "inline",
  BLOCKTYPE: "blockType",
  LIST: "list",
};

export const TOOLBAR_INLINE_OPTIONS = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
};

export const BLOCKTYPE_OPTIONS = {
  NORMAL: "Normal",
  H1: "H1",
  H2: "H2",
  H3: "H3",
  H4: "H4",
  H5: "H5",
  H6: "H6",
  BLOCKQUOTE: "Blockquote",
};

export const LIST_OPTIONS = {
  UNORDERED: "unordered",
  ORDERED: "ordered",
};

export const KEY_DOWN = {
  ARROWUP: "ArrowUp",
  ARROWDOWN: "ArrowDown",
  ENTER: "Enter",
  ESCAPE: "Escape",
};

export const TAG_REGEX =
  /@([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|@([\p{L}0-9._-]+)/gu;
export const REGEX = /@(\w*)$/;
export const REGEX_DELETE_SPACE = /\s+/g;

export const SETTLED_STATUS = {
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
} as const;

export const FAB_KEY = {
  PHOTO_VIDEO: "photo-video",
  IMAGE: "image",
  DOCUMENT: "document",
  STICKER: "sticker",
  CONTACT: "contact",
};

export enum MESSAGE_OPTIONS_ACTION {
  MESSAGE_IN_THREAD = "message-in-thread",
  DELETE = "delete",
  REPLY = "reply",
  PIN = "pin",
}

export const availableOptions = {
  message: [
    {
      title: "Message in thread",
      id: MESSAGE_OPTIONS_ACTION.MESSAGE_IN_THREAD,
    },
    {
      title: "Delete",
      id: MESSAGE_OPTIONS_ACTION.DELETE,
    },
    // {
    //   title: "Reply",
    //   id: MESSAGE_OPTIONS_ACTION.REPLY,
    // },
    // {
    //   title: "Pin",
    //   id: MESSAGE_OPTIONS_ACTION.PIN,
    // },
  ],
  messageNoDelete: [
    {
      title: "Message in thread",
      id: MESSAGE_OPTIONS_ACTION.MESSAGE_IN_THREAD,
    },
    // {
    //   title: "Reply",
    //   id: MESSAGE_OPTIONS_ACTION.REPLY,
    // },
    // {
    //   title: "Pin",
    //   id: MESSAGE_OPTIONS_ACTION.PIN,
    // },
  ],
  thread: [
    {
      title: "Delete",
      id: MESSAGE_OPTIONS_ACTION.DELETE,
    },
  ],
};

export const FIELD_TYPE_MAP = {
  [FieldValueTypeEnum.displayType]: ROOM_DISPLAY_TYPE,
  [FieldValueTypeEnum.encryptType]: ROOM_ENCRYPT_TYPE,
  [FieldValueTypeEnum.connectionType]: ROOM_CONNECTION_TYPE,
};

export const TYPE_FC_USER = {
  BAN: "Ban",
  REMOVE: "Remove",
} as const;

export const REGEX_EMAIL = /@.*$/;

export const ROOM_FETCH_LIMIT = 10;
export const ROOM_FETCH_OFFSET = 0;
