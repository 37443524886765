import { createSlice } from "@reduxjs/toolkit";
import { SettingState } from "../types";

const initialState: SettingState = {
  settings: undefined,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    getConfigSettings: (state) => {},
  },
});

export const { setSettings, getConfigSettings } = settingSlice.actions;
export default settingSlice.reducer;
