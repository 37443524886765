import { DarkMode, LightMode } from "@mui/icons-material";
import { Box, Popover, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import useSettings from "../hooks/useSettings";
import { Theme } from "../types/theme";
import StyledIconButton from "./StyledIconButton";

const ThemeToggle: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { onToggleMode, themeMode } = useSettings();

  const handleChangeTheme = useCallback(
    (mode: Theme) => {
      if (themeMode !== mode) onToggleMode();
      setAnchorEl(null);
    },
    [themeMode, onToggleMode],
  );

  const themeMap = useMemo(
    () => ({
      light: {
        lightColor: "primary",
        darkColor: "default",
        icon: <LightMode />,
      },
      dark: { lightColor: "default", darkColor: "primary", icon: <DarkMode /> },
    }),
    [],
  );

  return (
    <div>
      <Tooltip title="Theme Setting" placement="right">
        <StyledIconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          color="primary"
        >
          {themeMap[themeMode].icon}
        </StyledIconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box sx={{ p: 2, minWidth: 180, textAlign: "center" }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Choose Theme
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Tooltip title="Light Mode">
              <StyledIconButton
                onClick={() => handleChangeTheme(Theme.light)}
                color={themeMap[themeMode].lightColor}
              >
                <LightMode />
              </StyledIconButton>
            </Tooltip>

            <Tooltip title="Dark Mode">
              <StyledIconButton
                onClick={() => handleChangeTheme(Theme.dark)}
                color={themeMap[themeMode].darkColor}
              >
                <DarkMode />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default ThemeToggle;
