import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MessageState } from "../types";

export const selectMessageState = (state: RootState): MessageState =>
  state.message;

export const selectMessageListByRoom = createSelector(
  [selectMessageState],
  (message) => message.messageListByRoom
);

export const selectSelectedThread = createSelector(
  [selectMessageState],
  (message) => message.selectedThread
);

export const selectLoadingMessages = createSelector(
  [selectMessageState],
  (message) => message.loadingMessages
);

export const selectTotalMessagesByRoom = createSelector(
  [selectMessageState],
  (message) => message.totalMessagesByRoom
);

export const selectDataSendingMessage = createSelector(
  [selectMessageState],
  (message) => message.dataSendingMessage
);

export const selectIdsRefresh = createSelector(
  [selectMessageState],
  (message) => message.idsRefresh
);
