import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { X } from "phosphor-react";
import { useCallback, useEffect, useState } from "react";
import logo from "../../assets/Images/jchat-beta.svg";
import { LocalStorageKey } from "../../enums";

export default function AppNotification({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const theme = useTheme();

  const handleChecked = useCallback(() => {
    setIsChecked((prev) => !prev);
  }, []);

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.Checked, String(isChecked));
  }, [isChecked]);

  return (
    <Stack
      sx={{
        zIndex: 10,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: (theme) =>
          theme.palette.mode === "light"
            ? `${theme.palette.grey[700]}80`
            : `${theme.palette.text.secondary}80`,
        boxShadow: "0 12px 28px 0",
      }}
    >
      <Stack
        sx={{
          width: "650px",
          height: "650px",
          overflow: "hidden",
          borderColor: "gray",
          bgcolor: (theme) => theme.palette.background.default,

          borderRadius: 1,
        }}
      >
        <Stack
          width={"100%"}
          p={2}
          position={"relative"}
          textAlign={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <Box
            sx={{
              width: 200,
              objectFit: "contant",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <img src={logo} alt="logo" />
          </Box>
          <IconButton
            sx={{
              position: "absolute",
              right: 5,
            }}
            onClick={() => onDismiss()}
          >
            <X />
          </IconButton>
        </Stack>
        <Divider />
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
            mt: 1,
            px: 5,
            gap: 2,
          }}
        >
          <Typography variant="body1" textAlign={"center"} fontSize={22}>
            <b>Thông Báo: Dùng Thử Ứng Dụng jChat!</b>
          </Typography>
          <Typography>
            Hân hạnh giới thiệu jChat – ứng dụng liên lạc tối ưu dành cho bạn!
            Với jChat, bạn có thể:
          </Typography>
          <ul style={{ listStyle: "none" }}>
            <li>- Chat trực tiếp với bạn bè và đồng nghiệp.</li>
            <li>- Gọi voice, video sắc nét, ổn định.</li>
            <li>- Tạo và quản lý nhóm chat dễ dàng.</li>
            <li>
              - Bảo mật tối đa với tính năng mã hóa tin nhắn và mã hóa đầu cuối.
            </li>
          </ul>
          <Typography>
            Đây là cơ hội để bạn trải nghiệm tất cả tính năng cao cấp hoàn toàn
            miễn phí, đơn giản, tiện lợi và an toàn – jChat mang đến kết nối
            toàn diện ở mọi lúc, mọi nơi.
          </Typography>

          <Typography>
            Chúng tôi rất mong nhận được sự phản hồi từ bạn!{" "}
            <span>
              <a
                href="https://web.jchat.io/app/JChat-Support-eqxg7k"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: theme.palette.error.dark,
                  fontWeight: "bold",
                }}
                onClick={() => onDismiss()}
              >
                Hỗ trợ
              </a>
            </span>
          </Typography>
        </Stack>

        <Stack
          sx={{
            díplay: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            px: 5,
            pb: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "-10px",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={handleChecked}
          >
            <Checkbox checked={isChecked} size="small" />
            <Typography fontSize={14} sx={{ marginLeft: "-4px" }}>
              Không hiển thị lại
            </Typography>
          </Stack>

          <Button variant="contained" size="medium" onClick={() => onDismiss()}>
            Đồng ý
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
