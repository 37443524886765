import { Room, UserMessage, UserRoom } from "../services";
import { RoomInfo } from "../types/room";

export interface GoogleExternalUserInfo {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  profile: string;
  picture: string;
  email: string;
  email_verified: boolean;
  gender: string;
}

export interface AppleUserInfo {
  id: string;
  created_at: string;
  updated_at: string;
  object_system_key: string;
  is_active: boolean;
}

export interface AppleExternalUserInfo {
  iss: string;
  aud: string;
  exp: number;
  iat: number;
  sub: string;
  nonce: string;
  at_hash: string;
  email: string;
  email_verified: string;
  is_private_email: string;
  auth_time: number;
  nonce_supported: boolean;
}

export interface FacebookExternalUserInfo {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
}

export interface GoogleExternalUserInfoResponse {
  token: string;
  external_user_info: GoogleExternalUserInfo;
}

export interface AppleExternalUserInfoResponse {
  token: string;
  user_info: AppleUserInfo;
  external_user_info: AppleExternalUserInfo;
}

export interface FacebookExternalUserInfoResponse {
  token: string;
  external_user_info: FacebookExternalUserInfo;
}

export interface UserInfo {
  id: string;
  created_at: string;
  updated_at: string;
  object_system_key: string;
  is_active: false;
  user_profile_info: {
    id: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    address_info: [];
    avatar: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    birthday?: string;
    gender?: boolean;
    phone_number?: number;
  };
  external_user_info?: (
    | AppleExternalUserInfo
    | GoogleExternalUserInfo
    | FacebookExternalUserInfo
  )[];
  username?: string;
}

export interface ChatUserInfo {
  id: string;
  email: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  isActive: true;
  avatar: string;
  bio: string;
}

export type SocialType = "google" | "facebook" | "apple";

export type LoginFormData = {
  username: string;
  password: string;
  remember_me: boolean;
};

export interface UserDeviceInfo {
  created_at: string;
  creator_id: string;
  device_id: string;
  device_token: string;
  id: string;
  is_active: boolean;
  operating_system: string;
  type: string;
  updated_at: string;
  user_id: string;
}

export interface AuthState {
  userData: UserInfo | null;
  chatUserData: ChatUserInfo | null;
  isPasswordSet: boolean;
  fcmToken: string;
  userDeviceInfo: UserDeviceInfo;
  loading: boolean;
  loadingLogin: boolean;
}

export enum LOGIN_TYPE_DATA {
  normal = "normal",
}

export const MS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

export const DAYS_32 = 32;

export interface RoomState {
  rooms: Record<string, Room>;
  ids: string[];
}

export interface RoomsSearch {
  rooms: Record<string, RoomState>;
  ids: string[];
}

export interface ChatState {
  rooms: RoomState;
  roomsSearch: RoomsSearch;
  isLoading: boolean;
  total: number;
  search: string;
  selectedRoom: Room | undefined;
  selectedRoomMembers: Record<string, UserRoom>;
  selectedRoomCreatorUser: UserRoom | undefined;
  chatUnreadCount: object;
  searchValue: string;
  isLoadMore: boolean;
}

export interface RoomWithMessageList extends Room {
  messages: UserMessage[];
}

export interface MessageState {
  messageListByRoom: {
    rooms: Record<string, RoomWithMessageList>;
    roomIds: string[];
  };
  selectedThread: UserMessage | undefined;
  loadingMessages: boolean;
  totalMessagesByRoom: Record<string, number>;
  dataSendingMessage: UserMessage[];
  idsRefresh: string[];
}

export interface SettingState {
  settings: Record<string, any> | undefined;
}

export enum SETTINGS_KEYS {
  user_register_enable = "user_register_enable",
  support_room_url = "support_room_url",
}
