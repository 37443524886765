import { put, select, takeLatest } from "redux-saga/effects";
import { onUpdateOTPTimeInfo, setOtpTimeInfo } from "../slices/otp";
import { InputOTPType } from "../../constant/profile";
import { OTPTimeInfo } from "../../contexts";
import { selectedOtpTimeInfo } from "../selectors/otp";

function* onUpdateOTPTimeInfoSaga(action: {
  payload: { type: InputOTPType; otpInfo: OTPTimeInfo };
}) {
  const { type, otpInfo } = action.payload;
  if (!type) return;
  const otpTimeInfo = yield select(selectedOtpTimeInfo);
  yield put(setOtpTimeInfo({ ...otpTimeInfo, [type]: otpInfo }));
}

export function* watchOTP() {
  yield takeLatest(onUpdateOTPTimeInfo, onUpdateOTPTimeInfoSaga);
}
