import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default StyledIconButton;
