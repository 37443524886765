export const PROFILE_TYPE = {
  EMAIL: "email",
  USERNAME: "username",
  PHONE_NUMBER: "phone_number",
} as const;

export const TYPE_OTP = {
  CHANGE_USERNAME: "change-username",
  CHANGE_PHONE: "change-phone",
  REGISTER: "register",
  CHANGE_PASSWORD: "change-password",
  CHANGE_EMAIL: "change-email",
  EXTERNAL_SET_PASSWORD: "external-set-password",
} as const;

export type InputOTPType = (typeof TYPE_OTP)[keyof typeof TYPE_OTP];

export type TypeProfile = (typeof PROFILE_TYPE)[keyof typeof PROFILE_TYPE];

export const PROFILE_PUBLIC = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  BIRTHDAY: "birthday",
  GENDER: "gender",
  BIO: "bio",
} as const;

export type TypeProfilePublic =
  (typeof PROFILE_PUBLIC)[keyof typeof PROFILE_PUBLIC];
