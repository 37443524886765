import dayjs from "dayjs";
import { TimeFormat } from "../types/time";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const formatTime = (time: string | Date, timeFormat?: TimeFormat) => {
  return dayjs(new Date(time)).format(timeFormat ?? "dd-MM-yyyy");
};

export const formatExpireTime = (time: string | Date) => {
  return dayjs(new Date(time)).toNow();
};

export const formatRelativeTime = ({ minutes, seconds }) => {
  return dayjs().minute(minutes).second(seconds).format("mm:ss");
};

export const formatRelativeTimeFromNow = (time: string | Date) => {
  const date = new Date(time);
  if (date.toDateString() === new Date().toDateString()) {
    return dayjs(date).format("HH:mm");
  }
  // return week day if in same week
  if (dayjs().diff(date, "week") === 0) {
    return dayjs(date).format("dddd");
  }
  // return day and month if in same year
  if (dayjs().diff(date, "year") === 0) {
    return dayjs(date).format("DD/MMM");
  }
  // return month and year if not in same year
  return dayjs(date).format("MMM/YYYY");
};

export const isMessageOver24Hours = (createAt) => {
  const createdDate = new Date(createAt);
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  return createdDate < currentDate;
};
