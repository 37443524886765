import { AxiosResponse } from "axios";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";

import { getToken, onMessage } from "firebase/messaging";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../components/LoadingWrapper";
import { VAPID_KEY } from "../config";
import { LocalStorageKey } from "../constant/local-storage";
import { messaging } from "../firebase/firebaseConfig";
import {
  ChatAxios,
  ChatWSAxios,
  CustomerAxios,
  getRequestHeaders,
  NotificationAxios,
} from "../services/axios";
import { register, unregister } from "../serviceWorker";
import {
  CookiesKeys,
  getCookie,
  removeCookie,
  setCookie,
} from "../utils/cookie";
import { getErrorMessage } from "../utils/error";
import { getUserKeyPair } from "../utils/local-storage";
import SetPasswordDialog from "../components/set-password-dialog";

export interface GoogleExternalUserInfo {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  profile: string;
  picture: string;
  email: string;
  email_verified: boolean;
  gender: string;
}

interface AppleUserInfo {
  id: string;
  created_at: string;
  updated_at: string;
  object_system_key: string;
  is_active: boolean;
}

export interface AppleExternalUserInfo {
  iss: string;
  aud: string;
  exp: number;
  iat: number;
  sub: string;
  nonce: string;
  at_hash: string;
  email: string;
  email_verified: string;
  is_private_email: string;
  auth_time: number;
  nonce_supported: boolean;
}

export interface FacebookExternalUserInfo {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
}

export interface GoogleExternalUserInfoResponse {
  token: string;
  external_user_info: GoogleExternalUserInfo;
}

export interface AppleExternalUserInfoResponse {
  token: string;
  user_info: AppleUserInfo;
  external_user_info: AppleExternalUserInfo;
}

export interface FacebookExternalUserInfoResponse {
  token: string;
  external_user_info: FacebookExternalUserInfo;
}

export interface UserInfo {
  id: string;
  created_at: string;
  updated_at: string;
  object_system_key: string;
  is_active: false;
  user_profile_info: {
    id: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    address_info: [];
    avatar: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    birthday?: string;
    gender?: boolean;
    phone_number?: number;
  };
  external_user_info?: (
    | AppleExternalUserInfo
    | GoogleExternalUserInfo
    | FacebookExternalUserInfo
  )[];
}

export interface ChatUserInfo {
  id: string;
  email: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  isActive: true;
  avatar: string;
  bio: string;
}

export type SocialType = "google" | "facebook" | "apple";

export type LoginFormData = {
  username: string;
  password: string;
  remember_me: boolean;
};

export interface UserDeviceInfo {
  created_at: string;
  creator_id: string;
  device_id: string;
  device_token: string;
  id: string;
  is_active: boolean;
  operating_system: string;
  type: string;
  updated_at: string;
  user_id: string;
}

const AuthContext = createContext<{
  userData: UserInfo | null;
  setUserData: Dispatch<SetStateAction<UserInfo | null>>;
  chatUserData: ChatUserInfo | null;
  setChatUserData: (chatUserData: ChatUserInfo | null) => void;
  logout: () => void;
  login: (values: LoginFormData) => Promise<void>;
  loginSocial: (
    values: AxiosResponse<
      | GoogleExternalUserInfoResponse
      | AppleExternalUserInfoResponse
      | FacebookExternalUserInfoResponse
    >,
    type: SocialType,
  ) => Promise<void>;
  isPasswordSet: boolean;
  setIsPasswordSet: Dispatch<SetStateAction<boolean>>;
  checkIsPasswordSet: () => Promise<boolean>;
  fcmToken: string;
  setFcmToken: Dispatch<SetStateAction<string>>;
}>({
  userData: null,
  setUserData: () => {},
  chatUserData: null,
  setChatUserData: () => {},
  logout: () => {},
  login: async () => {},
  loginSocial: async () => {},
  isPasswordSet: false,
  setIsPasswordSet: () => {},
  checkIsPasswordSet: async () => false,
  fcmToken: "",
  setFcmToken: () => {},
});

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [userData, setUserData] = useState<UserInfo | null>(null);
  const [chatUserData, setChatUserData] = useState<ChatUserInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [fcmToken, setFcmToken] = useState("");
  const [userDeviceInfo, setUserDeviceInfo] = useState<UserDeviceInfo>();
  const logout = () => {
    if (userDeviceInfo) {
      NotificationAxios.putReq(`/user-device/update/${userDeviceInfo.id}`, {
        device_token: fcmToken,
        operating_system: "web",
        device_id: navigator.userAgent,
        is_active: false,
      });
      setUserDeviceInfo(undefined);
    }
    localStorage.clear();
    removeCookie(CookiesKeys.auth_data);
    removeCookie(CookiesKeys.login_type);
    removeCookie(CookiesKeys.user_register_enable);
    // TODO: revert this
    setUserData(null);
    setChatUserData(null);
    // setRooms([]);
    // setMessages({});
    // setSelectedRoom(undefined);
    // setSelectedThread(undefined);
    unregister();
  };

  // TODO: revert this
  // const { userKeys, setUserKeys } = useKeysStore();

  const login = async (values: LoginFormData) => {
    const timeExpire = new Date(Date.now() + 32 * 24 * 60 * 60 * 1000); // 32 days

    const result = await CustomerAxios.postReq("/user/login", values);
    setCookie(
      CookiesKeys.auth_data,
      {
        ...result.data,
        user_info: {
          id: result.data.user_info.id,
        },
      },
      {
        ...(values.remember_me && {
          expires: timeExpire,
        }),
      },
    );
    setCookie(CookiesKeys.login_type, "normal", {
      ...(values.remember_me && {
        expires: timeExpire,
      }),
    });
    const resultKey = await ChatAxios.getReq("generate-key", {
      headers: getRequestHeaders(),
    });
    localStorage.setItem(
      LocalStorageKey.KeyPair,
      JSON.stringify(resultKey.data),
    );

    // TODO: revert this
    // setUserKeys({
    //   ...userKeys,
    //   [result.data.user_info.id]: resultKey.data.public_key,
    // });

    const privateUserInfo = await CustomerAxios.postReq("/user/private/get", {
      password: values.password,
    });

    await ChatWSAxios.postReq("/api/users/upsert", {
      id: result.data.user_info.id,
      username: result.data.user_info.username,
      email: privateUserInfo.data.email,
      avatar: privateUserInfo.data.avatar,
    });

    const resultUserInfo = await CustomerAxios.getReq("/user/get-info");
    setUserData(resultUserInfo.data);

    const resultChat = await ChatWSAxios.getReq("api/users/get-info");
    setChatUserData(resultChat);
  };

  const loginSocial = async (
    values: AxiosResponse<
      | GoogleExternalUserInfoResponse
      | AppleExternalUserInfoResponse
      | FacebookExternalUserInfoResponse
    >,
    type: SocialType,
  ) => {
    setCookie(CookiesKeys.auth_data, values.data);
    setCookie(CookiesKeys.login_type, type);

    const resultUserInfo = await CustomerAxios.getReq("/user/get-info");

    setCookie(CookiesKeys.auth_data, {
      ...values.data,
      user_info: { id: resultUserInfo.data?.id },
    });

    const resultKey = await ChatAxios.getReq("generate-key", {
      headers: getRequestHeaders(),
    });
    localStorage.setItem(
      LocalStorageKey.KeyPair,
      JSON.stringify(resultKey.data),
    );

    // TODO: revert this
    // setUserKeys({
    //   ...userKeys,
    //   [values.data.external_user_info.email]: resultKey.data.public_key,
    // });

    await ChatWSAxios.postReq("/api/users/upsert", {
      id: values.data.external_user_info.email,
      username: values.data.external_user_info.email,
      email: values.data.external_user_info.email,
      avatar: (values.data.external_user_info as any)?.picture,
    });

    setUserData(resultUserInfo.data);

    const resultChat = await ChatWSAxios.getReq("api/users/get-info");
    setChatUserData(resultChat);
    await checkIsPasswordSet();
  };

  const checkIsPasswordSet = useCallback(async () => {
    const localStoragePasswordIsSet = localStorage.getItem(
      LocalStorageKey.PasswordIsSet,
    );

    if (
      localStoragePasswordIsSet !== null &&
      localStoragePasswordIsSet === "true"
    ) {
      setIsPasswordSet(true);
      return true;
    }

    const resultIsPassworSet = await CustomerAxios.getReq(
      "/user/password-is-set",
    );
    setIsPasswordSet(resultIsPassworSet.data.password_is_set ?? false);
    localStorage.setItem(
      LocalStorageKey.PasswordIsSet,
      resultIsPassworSet.data.password_is_set,
    );
    return !!resultIsPassworSet.data.password_is_set;
  }, []);

  const navigate = useNavigate();

  const getUserInfo = useCallback(async () => {
    const cookieUser = getCookie(CookiesKeys.auth_data);
    try {
      setLoading(true);
      if (!isEmpty(cookieUser?.token)) {
        const result = await CustomerAxios.getReq("/user/get-info");
        const resultChat = await ChatWSAxios.getReq("api/users/get-info");
        setChatUserData(resultChat);
        setUserData(result.data);
        let keyPair = getUserKeyPair();
        if (isEmpty(keyPair)) {
          const resultKey = await ChatAxios.getReq("generate-key", {
            headers: getRequestHeaders(),
          });
          localStorage.setItem(
            LocalStorageKey.KeyPair,
            JSON.stringify(resultKey.data),
          );
          keyPair = resultKey.data;
        }
        // TODO: revert this
        // setUserKeys({
        //   ...userKeys,
        //   [result.data.id]: keyPair.public_key,
        // });

        await checkIsPasswordSet();
      } else {
        if (!window.location.pathname.includes("/auth")) {
          navigate("/auth/login");
        }
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, []);

  const [isPasswordSet, setIsPasswordSet] = useState(true);

  async function requestToken() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });

      setFcmToken(token);

      onMessage(messaging, (payload) => {
        const messageSound = new Audio("/notification.mp3");
        messageSound.play();
      });
    } else if (permission === "denied") {
      //notifications are blocked
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const registerDevice = async () => {
      const result = await NotificationAxios.postReq("/user-device/upsert", {
        device_token: fcmToken,
        operating_system: "web",
        device_id: navigator.userAgent,
        is_active: true,
      });

      setUserDeviceInfo(result.data);
      register();
    };

    if (fcmToken && userData) {
      registerDevice();
    }
  }, [fcmToken, userData]);

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        chatUserData,
        setChatUserData,
        logout,
        login,
        loginSocial,
        isPasswordSet,
        setIsPasswordSet,
        checkIsPasswordSet,
        fcmToken,
        setFcmToken,
      }}
    >
      {loading ? <LoadingWrapper isLoading /> : children}
      {!isPasswordSet && <SetPasswordDialog />}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
