const WORKER_PATH = "/firebase-messaging-serviceworker-prod.js";

export const register = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register(WORKER_PATH).catch((error) => {
      console.error("Error registering service worker:", error);
    });
  }
};

export const unregister = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      if (
        registration.active.scriptURL ===
        window.location.origin + WORKER_PATH
      ) {
        registration.unregister();
      }
    });
  }
};
