import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageState } from "../types";
import { Room, UserMessage } from "../../services";
import { PaginationLimitOffset } from "../../types/room";

export const initialState: MessageState = {
  messageListByRoom: { roomIds: [], rooms: {} },
  selectedThread: undefined,
  loadingMessages: false,
  totalMessagesByRoom: {},
  dataSendingMessage: [],
  idsRefresh: [],
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessageListByRoom: (state, action) => {
      state.messageListByRoom = action.payload;
    },
    setSelectedThread: (state, action) => {
      state.selectedThread = action.payload;
    },
    setLoadingMessages: (state, action) => {
      state.loadingMessages = action.payload;
    },
    setTotalMessagesByRoom: (state, action) => {
      state.totalMessagesByRoom = action.payload;
    },
    setDataSendingMessage: (state, action) => {
      state.dataSendingMessage = action.payload;
    },
    setIdsRefresh: (state, action) => {
      state.idsRefresh = action.payload;
    },
    getRoomMessagesFromLocalStorage: (state, action) => {
      const messageListByRoom = action.payload;
      state.messageListByRoom = {
        ...state.messageListByRoom,
        [messageListByRoom.id]: messageListByRoom,
      };
    },
    getRoomMessagesFromServer: (
      state,
      action: PayloadAction<{ room: Room; params: PaginationLimitOffset }>
    ) => {},
    handleCreateNewMessage: (state, action) => {},
    handleAddSendingMessage: (state, action) => {
      state.dataSendingMessage = [...state.dataSendingMessage, action.payload];
    },
    handleRemoveSendingMessage: (state, action) => {
      const updatedMessages = state.dataSendingMessage.filter(
        (item) => item?.additionalData?.messageId !== action.payload
      );
      state.dataSendingMessage = updatedMessages;
    },
    getRoomMessageLatest: (
      state,
      action: PayloadAction<{
        latestUserMessageId: string;
        room: Room;
        params: PaginationLimitOffset;
      }>
    ) => {},
    clearMessage: () => initialState,
    removeMessage: (state, action: PayloadAction<{ roomId: string }>) => {
      const { roomId } = action.payload;

      delete state.messageListByRoom.rooms[roomId];
      state.messageListByRoom.roomIds = state.messageListByRoom.roomIds.filter(
        (id) => id !== roomId
      );
      delete state.totalMessagesByRoom[roomId];
    },
    updateMessages: (
      state,
      action: PayloadAction<{ messages: UserMessage[]; room?: Room }>
    ) => {
      const { messages, room } = action.payload;
      state.messageListByRoom = {
        ...state.messageListByRoom,
        rooms: {
          ...state.messageListByRoom.rooms,
          [room.id]: room
            ? {
                ...room,
                messages: messages,
              }
            : {
                ...state.messageListByRoom.rooms[room.id],
                messages: messages,
              },
        },
        roomIds: state.messageListByRoom.roomIds.includes(room.id)
          ? state.messageListByRoom.roomIds
          : [...state.messageListByRoom.roomIds, room.id],
      };
    },
    deleteMessage: (state, action) => {},
  },
});

export const {
  setDataSendingMessage,
  setLoadingMessages,
  setMessageListByRoom,
  setSelectedThread,
  setTotalMessagesByRoom,
  getRoomMessagesFromServer,
  handleAddSendingMessage,
  handleCreateNewMessage,
  handleRemoveSendingMessage,
  getRoomMessagesFromLocalStorage,
  clearMessage,
  getRoomMessageLatest,
  removeMessage,
  updateMessages,
  setIdsRefresh,
  deleteMessage,
} = messageSlice.actions;

export default messageSlice.reducer;
