export const TERMS_SERVICE: {
  id: number;
  title: string;
  contents: string[];
}[] = [
  {
    id: 1,
    title: "Public Nature of the Room",
    contents: [
      "A public room is a public space where everyone can view the content shared in the room, including messages and information, even if they are not directly participating in the room.",
      "You are fully responsible for the content you share in a public room.",
    ],
  },
  {
    id: 2,
    title: "Security and Privacy",
    contents: [
      "The platform cannot guarantee absolute security or privacy for content shared in a public room.",
      "Users should carefully consider before sharing personal, sensitive, or any information that may impact themselves or third parties.",
    ],
  },
  {
    id: 3,
    title: "Risks and Responsibilities",
    contents: [
      "The platform is not responsible for any risks related to the misuse, unauthorized distribution, or harm caused by the content in public rooms.",
      "You agree that using a public room is entirely voluntary, and you accept all risks associated with sharing content here.",
    ],
  },
  {
    id: 4,
    title: "Prohibited Conduct",
    contents: [
      "Using public rooms to share illegal, abusive, offensive, or rights-violating content is strictly prohibited.",
      "The platform reserves the right to remove content or suspend access to the room if violations are detected.",
    ],
  },
  {
    id: 5,
    title: "User Commitment",
    contents: [
      "By creating and using a public room, you confirm that you have read, understood, and agreed to comply with these terms.",
    ],
  },
];

export const TYPE_STEP = {
  INFO: "info",
  TERM: "term",
  MEMBER: "member",
};

export enum FieldValueTypeEnum {
  displayType = "displayType",
  connectionType = "connectionType",
  encryptType = "encryptType",
  isTermAccepted = "isTermAccepted",
  name = "name",
  messageType = "messageType",
  roomMembers = "roomMembers",
}
