import { Container, Stack } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import Logo from "../../assets/Images/jchat-beta.svg";
import { selectChatUserData } from "../../redux/selectors/auth";
import { useAppSelector } from "../../redux/store";

const MainLayout = () => {
  const chatUserData = useAppSelector(selectChatUserData);

  const isAuthenticated = !!chatUserData;

  if (isAuthenticated) {
    return <Navigate to="/app" />;
  }

  return (
    <Container sx={{ mt: 5 }} maxWidth="sm">
      <Stack spacing={5}>
        <Stack sx={{ width: "100%" }} direction="column" alignItems={"center"}>
          <img
            style={{
              height: 120,
              width: 120,
              borderRadius: 5,
            }}
            src={Logo}
            alt="Logo"
          />
        </Stack>
      </Stack>
      <Outlet />
    </Container>
  );
};

export default MainLayout;
