import { toast } from "react-toastify";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import {
  ENCRYPT_TYPE,
  MESSAGE_TYPE,
  SETTLED_STATUS,
  SUBTYPE,
} from "../../constant/room";
import { SocketEventTypeEnum } from "../../enums";
import { findUsernameTagged } from "../../helpers/chat-user-helper";
import { isMessageContentValid } from "../../helpers/message-helper";
import { getUserRoomDisplayName } from "../../helpers/user-room";
import {
  GetListRoomMessageResponse,
  Message,
  MessageMeta,
  MessageType,
  MessageTypeEnum,
  Room,
  RoomEncryptTypeEnum,
  UserMessage,
  UserRoom,
} from "../../services";
import {
  handleDeleteMessage,
  handleGetMessageContent,
  handleGetRoomMessages,
} from "../../services/api";
import { AppMessageSubType } from "../../types";
import { NotificationInfo } from "../../types/chat-message";
import { PaginationLimitOffset } from "../../types/room";
import { mergeArrays } from "../../utils/array";
import {
  handleDecryptMessageContent,
  handleEncryptMessage,
} from "../../utils/encrypt";
import { getErrorMessage } from "../../utils/error";
import { getOneLocalStorageRoomEncryptKey } from "../../utils/local-storage";
import { selectUserData } from "../selectors/auth";
import {
  selectMessageListByRoom,
  selectTotalMessagesByRoom,
} from "../selectors/message";
import {
  deleteMessage,
  getRoomMessageLatest,
  getRoomMessagesFromServer,
  handleAddSendingMessage,
  handleCreateNewMessage,
  setLoadingMessages,
  setMessageListByRoom,
  setTotalMessagesByRoom,
  updateMessages,
} from "../slices/message";
import { PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import { selectSelectedRoom } from "../selectors/chat";

function* getRoomMessagesFromServerSaga(action: {
  payload: {
    room: Room;
    params: PaginationLimitOffset;
  };
}) {
  const { room, params } = action.payload;
  const { id: roomId } = room;
  const totalMessagesByRoom = yield select(selectTotalMessagesByRoom);
  const messageListByRoom = yield select(selectMessageListByRoom);

  try {
    const isCached = messageListByRoom.roomIds.includes(roomId);
    if (!isCached || params.offset !== 0) yield put(setLoadingMessages(true));
    const messages = yield call(handleGetRoomMessages, {
      roomId,
      ...params,
    });

    yield call(processMessageList, room, {
      ...messages,
      offset: params.offset,
    });

    yield put(
      setTotalMessagesByRoom({
        ...totalMessagesByRoom,
        [roomId]: messages.total,
      }),
    );
  } catch (error) {
    console.error("Error get room messages", error);
    toast.error("Error get room messages");
    yield put(setLoadingMessages(false));
  } finally {
    yield put(setLoadingMessages(false));
  }
}

function* processMessageList(room: Room, data: GetListRoomMessageResponse) {
  const { id: roomId } = room;
  let messageContents: Record<
    string,
    { content: string; messageMeta: string }
  > = {};

  let messagesToGetContent = data.messages
    .filter((item) => !isMessageContentValid(item))
    .map((item) => item?.messageId);
  messagesToGetContent = messagesToGetContent.concat(
    data.messages.reduce((pre, curr) => {
      return (pre ?? []).concat(
        (curr.threadMessages ?? [])
          ?.filter((item) => !isMessageContentValid(item))
          .map((item) => item?.messageId) ?? [],
      );
    }, [] as string[]),
  );

  if (messagesToGetContent.length > 0) {
    try {
      const messages = yield call(
        handleGetMessageContent,
        messagesToGetContent,
      );
      if (!messages || !messages.data) {
        toast.error("Failed to fetch message content!");
        return;
      }
      messageContents = messages.data.reduce(
        (pre, curr) => ({
          ...pre,
          [curr?.id]: {
            content: curr.content,
            messageMeta: curr.message_meta,
          },
        }),
        {},
      );
    } catch (error) {
      console.error("cannot get message content", error);
      toast.error("Network unstable! Please try again.");
      return;
    }
  } else {
    messageContents = {};
  }

  let newMessages = data.messages.map((message) => {
    if (!messageContents[message?.messageId]) {
      return message;
    }

    return {
      ...message,
      message: {
        ...message.message,
        content:
          messageContents[message?.messageId].content ??
          message.message.content,
        messageMeta:
          messageContents[message?.messageId].messageMeta ??
          message.message.messageMeta,
      },
      threadMessages:
        message.threadMessages?.map((thread) => {
          const currentMessage = messageContents[thread.messageId];
          if (!currentMessage) {
            return thread;
          }
          return {
            ...thread,
            message: {
              ...message.additionalData,
              ...thread.message,
              content: currentMessage.content ?? thread.message.content,
              messageMeta:
                currentMessage.messageMeta ?? thread.message.messageMeta,
            },
          };
        }) ?? [],
    };
  });

  const messagesMap = newMessages.reduce(
    (pre, curr) => {
      return {
        ...pre,
        [curr?.messageId]: curr,
        ...(curr.threadMessages.length > 0
          ? {
              [curr.threadMessages[0]?.messageId]: curr.threadMessages[0],
            }
          : {}),
      };
    },
    {} as Record<string, UserMessage>,
  );

  if (room?.encryptType !== RoomEncryptTypeEnum.none) {
    const decryptedMessagesPromise = Object.keys(messagesMap).map(
      async (messageId) => {
        try {
          const message = messagesMap[messageId];
          if (
            (room?.encryptType === RoomEncryptTypeEnum.key_exchange &&
              message.deviceInfo) ||
            room?.encryptType === RoomEncryptTypeEnum.aes_256_cbc
          ) {
            return await handleDecryptMessageContent(message.message, room);
          } else {
            return messagesMap;
          }
        } catch (error) {
          console.error(error);
        }
      },
    );

    const decryptedMessages = yield Promise.allSettled(
      decryptedMessagesPromise,
    );
    const decryptedMessagesMap = decryptedMessages.reduce(
      (pre, curr) => {
        if (curr.status === SETTLED_STATUS.FULFILLED) {
          return {
            ...pre,
            [curr?.value?.id]: curr.value,
          };
        }
        return pre;
      },
      {} as Record<string, Message>,
    );

    newMessages = newMessages.map((item) => ({
      ...item,
      message: {
        ...item.message,
        ...(decryptedMessagesMap[item?.messageId] ?? {}),
      },
      threadMessages: item.threadMessages.map((thread) => ({
        ...thread,
        message: {
          ...thread.message,
          ...(decryptedMessagesMap[thread?.messageId] ?? {}),
        },
      })),
    }));
  }

  const messageListByRoom = yield select(selectMessageListByRoom);
  const messages = mergeArrays(
    "id",
    messageListByRoom.rooms[roomId]?.messages ?? [],
    newMessages,
  );
  yield put(
    updateMessages({
      messages,
      room,
    }),
  );
}

function* getRoomMessageLatestSaga(action: {
  payload: {
    latestUserMessageId: string;
    params: PaginationLimitOffset;
    room: Room;
  };
}) {
  const { latestUserMessageId, params, room } = action.payload;
  const { id: roomId } = room;
  try {
    const messages = yield call(handleGetRoomMessages, {
      roomId,
      limit: params.limit,
      offset: params.offset,
      latestUserMessageId,
    });
    yield call(processMessageList, room, messages);
  } catch (error) {
    console.error("Error in getRoomMessageLatestSaga:", error);
  }
}

const getFilteredTagIds = (
  tagLists: string[],
  users: UserRoom[],
  userId: string,
) => {
  const filterIdTagList = tagLists.flatMap((tag) => {
    const user = users.find(
      (item) => tag === getUserRoomDisplayName(item, userId),
    );
    return user ? user.userId : [];
  });

  return filterIdTagList;
};

const sendMessage = async (
  data: {
    content?: string;
    messageType: MessageType;
    threadId?: string;
    messageMeta?: any;
    roomId: string;
    notificationInfo: NotificationInfo;
    additionalData: {
      messageId: string;
      content: string;
    };
  },
  socketRef,
) => {
  const {
    content,
    messageMeta,
    messageType,
    threadId,
    roomId,
    notificationInfo,
    additionalData,
  } = data;
  if (socketRef && socketRef.current) {
    socketRef.current.emit(SocketEventTypeEnum.addMessage, {
      content,
      threadId,
      messageMeta,
      messageType,
      roomId,
      notificationInfo,
      additionalData,
    });
  }
};

//TODO: use const selectedRoom = yield select(selectSelectedRoom); after convert ChatContext

function* handleCreateNewMessageSaga(action: {
  payload: {
    data: {
      messageType: MessageType;
      messageContent?: string;
      messageMeta?: string;
      threadId?: string;
      roomId?: string;
      notificationInfo: NotificationInfo;
      additionalData: {
        messageId: string;
        content: string;
      };
    };
    socketRef;
  };
}) {
  const { data, socketRef } = action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  const {
    messageType,
    messageContent,
    messageMeta,
    threadId,
    roomId,
    notificationInfo,
  } = data;

  try {
    if (!messageContent && !messageMeta) {
      toast.error("Message content is empty");
    }
    const messageId = uuidv4();
    const tagLists = yield call(findUsernameTagged, messageContent);
    const userData = yield select(selectUserData);
    const newIds: string[] = selectedRoom?.users
      ? getFilteredTagIds(tagLists, selectedRoom?.users, userData.id)
      : [];

    let meta: MessageMeta | null;
    if (messageMeta) {
      meta = JSON.parse(messageMeta);
    } else {
      meta = null;
    }

    let subtype: AppMessageSubType;
    if (messageType === MESSAGE_TYPE.FILE && meta) {
      if (meta.file_type.includes("image")) {
        subtype = SUBTYPE.IMG;
      } else {
        subtype = SUBTYPE.DOC;
      }
    } else {
      subtype = SUBTYPE.TEXT;
    }

    const data = {
      messageType,
      threadId,
      roomId,
      content: undefined,
      messageMeta: undefined,
      notificationInfo,
      additionalData: {
        messageId,
        content: messageContent,
        tagList: newIds,
      },
    };
    const currentDate = yield new Date();
    const newMessage = {
      messageId: messageId,
      createdAt: currentDate.toISOString(),
      senderId: userData?.id,
      receiverId: null,
      roomId: roomId,
      threadId,
      message: {
        subtype,
        id: messageId,
        messageMeta: null,
        content: messageContent,
        meta,
        messageType,
        img: meta?.plain_url,
      },
      threadMessages: [],
      additionalData: {
        messageId,
        content: messageContent,
        isSending: true,
        tagList: newIds,
      },
    };

    switch (messageType) {
      case MessageTypeEnum.file:
        if (selectedRoom?.encryptType) {
          const roomEncryptKey = yield call(
            getOneLocalStorageRoomEncryptKey,
            roomId,
          );
          try {
            data.messageMeta = yield call(handleEncryptMessage, {
              message: messageMeta,
              roomEncryptType: selectedRoom?.encryptType,
              key: roomEncryptKey,
            });
          } catch (error) {
            console.log("Error encrypt message meta");
            return;
          }
        } else {
          data.messageMeta = messageMeta;
        }

        break;
      default:
        if (selectedRoom?.encryptType) {
          const roomEncryptKey = yield call(
            getOneLocalStorageRoomEncryptKey,
            roomId,
          );

          try {
            data.content = yield call(handleEncryptMessage, {
              message: messageContent,
              roomEncryptType: selectedRoom?.encryptType,
              key: roomEncryptKey,
            });
          } catch (error) {
            console.error(error);
            return;
          }
        } else {
          data.content = messageContent;
        }
        break;
    }

    yield put(handleAddSendingMessage(newMessage));
    yield call(sendMessage, data, socketRef);
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
}

function* deleteMessageSaga(
  action: PayloadAction<{
    message: UserMessage;
    setLoading: (value: React.SetStateAction<boolean>) => void;
    onClose?: () => void;
  }>,
) {
  const { message, setLoading, onClose } = action.payload;
  setLoading(true);
  const messageListByRoom = yield select(selectMessageListByRoom);
  const totalMessagesByRoom = yield select(selectTotalMessagesByRoom);
  try {
    yield call(handleDeleteMessage, message.roomId, message.id);
    const newMessageByRoom = {
      ...messageListByRoom,
      rooms: {
        ...messageListByRoom.rooms,
        [message.roomId]: {
          ...messageListByRoom.rooms[message.roomId],
          messages: messageListByRoom.rooms[message.roomId].messages.filter(
            (item) => item.id !== message.id,
          ),
        },
      },
    };
    yield put(setMessageListByRoom(newMessageByRoom));
    yield put(
      setTotalMessagesByRoom({
        ...totalMessagesByRoom,
        [message.roomId]: totalMessagesByRoom[message.roomId] - 1,
      }),
    );
    toast.success("Delete message successfully!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  } finally {
    onClose();
    setLoading(false);
  }
}

export function* watchMessage() {
  yield takeLatest(getRoomMessagesFromServer, getRoomMessagesFromServerSaga);
  yield takeEvery(handleCreateNewMessage, handleCreateNewMessageSaga);
  yield takeEvery(getRoomMessageLatest, getRoomMessageLatestSaga);
  yield takeEvery(deleteMessage, deleteMessageSaga);
}
