import { Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { PermissionState } from "../../constant/permission";
import { useAppDispatch } from "../../redux/store";
import { requestToken } from "../../redux/slices/auth";

export const PermissionNotification = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [permission, setPermission] = useState<string>(Notification.permission);

  const handleAllow = useCallback(async () => {
    if (Notification.permission !== PermissionState.default) {
      return;
    }

    try {
      const newPermission = await Notification.requestPermission();
      dispatch(requestToken());
      setPermission(newPermission);
    } catch (error) {
      console.error("error:", error);
    }
  }, [setPermission]);

  return permission === PermissionState.default ? (
    <Stack
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
        width: 300,
        padding: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `${theme.palette.grey[900]}, 80`,
        zIndex: 20,
      }}
    >
      <Typography fontWeight={"bold"} textAlign="center">
        Bật thông báo để nhận tin nhắn mới!
      </Typography>
      <Stack direction="row" justifyContent="center" mt={2} gap={2}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setPermission("denied")}
        >
          Từ chối
        </Button>
        <Button variant="contained" color="primary" onClick={handleAllow}>
          Cho phép
        </Button>
      </Stack>
    </Stack>
  ) : null;
};
