import { AxiosResponse } from "axios";
import { getToken, onMessage } from "firebase/messaging";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { VAPID_KEY } from "../../config";
import { LocalStorageKey, LoginType } from "../../constant/local-storage";
import { messaging } from "../../firebase/firebaseConfig";
import {
  handleCheckPasswordIsSet,
  handleGenerateKey,
  handleGetChatUserData,
  handleGetUserInfo,
  handleLogin,
  handlePrivateInfo,
  handleUpdateUserDevice,
  handleUpsertUser,
  handleUpsertUserDevice,
} from "../../services/api";
import { register, unregister } from "../../serviceWorker";
import {
  CookiesKeys,
  getCookie,
  removeCookie,
  setCookie,
} from "../../utils/cookie";
import { getErrorMessage } from "../../utils/error";
import { checkLocalStoragePasswordIsSet } from "../../utils/check";
import { getUserKeyPair } from "../../utils/local-storage";
import { selectFcmToken, selectUserDeviceInfo } from "../selectors/auth";
import {
  checkIsPasswordSet,
  clearAuth,
  getUserInfo,
  login,
  loginSocial,
  logout,
  registerDevice,
  requestToken,
  setChatUserData,
  setFcmToken,
  setIsPasswordSet,
  setLoading,
  setLoadingLogin,
  setUserData,
  setUserDeviceInfo,
} from "../slices/auth";
import { clearChat } from "../slices/chat";
import { clearMessage } from "../slices/message";
import {
  AppleExternalUserInfoResponse,
  DAYS_32,
  FacebookExternalUserInfoResponse,
  GoogleExternalUserInfoResponse,
  LOGIN_TYPE_DATA,
  MS_IN_ONE_DAY,
  SocialType,
} from "../types";

function* logoutSaga() {
  const userDeviceInfo = yield select(selectUserDeviceInfo);
  if (userDeviceInfo) {
    const fcmToken = yield select(selectFcmToken);
    yield call(handleUpdateUserDevice, userDeviceInfo.id, fcmToken);
    yield put(setUserDeviceInfo(undefined));
  }
  localStorage.clear();
  removeCookie(CookiesKeys.auth_data);
  removeCookie(CookiesKeys.login_type);
  removeCookie(CookiesKeys.user_register_enable);

  yield put(clearAuth());
  yield put(clearChat());
  yield put(clearMessage());

  unregister();
}

function* loginSaga(action) {
  const data = action.payload;
  try {
    yield put(setLoadingLogin(true));
    const timeExpire = new Date(Date.now() + DAYS_32 * MS_IN_ONE_DAY); // 32 days
    const result = yield call(handleLogin, data);
    yield put(setUserData(result.data.user_info));
    setCookie(
      CookiesKeys.auth_data,
      {
        ...result.data,
        user_info: {
          id: result.data.user_info.id,
        },
      },
      {
        // ...(data.remember_me && {
        expires: timeExpire,
        // }),
      },
    );
    setCookie(CookiesKeys.login_type, LOGIN_TYPE_DATA.normal, {
      expires: timeExpire,
    });
    const resultKey = yield call(handleGenerateKey);
    localStorage.setItem(
      LocalStorageKey.KeyPair,
      JSON.stringify(resultKey.data),
    );
    const privateUserInfo = yield call(handlePrivateInfo, data.password);

    yield call(
      handleUpsertUser,
      result.data.user_info.id,
      result.data.user_info.username,
      privateUserInfo.data.email,
      privateUserInfo.data.avatar,
    );

    const resultChat = yield call(handleGetChatUserData);
    yield put(setChatUserData(resultChat));

    const fcmToken = yield select(selectFcmToken);
    if (fcmToken && result.data.user_info) yield put(registerDevice());
  } catch (error) {
    toast.error(getErrorMessage(error));
  } finally {
    yield put(setLoadingLogin(false));
  }
}

function* loginSocialSaga(action: {
  payload: {
    values: AxiosResponse<
      | GoogleExternalUserInfoResponse
      | AppleExternalUserInfoResponse
      | FacebookExternalUserInfoResponse
    >;
    type: SocialType;
  };
}) {
  setCookie(CookiesKeys.auth_data, action.payload.values.data);
  setCookie(CookiesKeys.login_type, action.payload.type);
  window.location.href = "/";
  const resultUserInfo = yield call(handleGetUserInfo);
  setCookie(CookiesKeys.auth_data, {
    ...action.payload.values.data,
    user_info: { id: resultUserInfo.data?.id },
  });

  const resultKey = yield call(handleGenerateKey);
  localStorage.setItem(LocalStorageKey.KeyPair, JSON.stringify(resultKey.data));

  yield call(
    handleUpsertUser,
    action.payload.values.data.external_user_info.email,
    action.payload.values.data.external_user_info.email,
    action.payload.values.data.external_user_info.email,
    (action.payload.values.data.external_user_info as any)?.picture,
  );

  const resultChat = yield call(handleGetChatUserData);
  yield put(setUserData(resultUserInfo.data));

  yield put(setChatUserData(resultChat));
  yield put(checkIsPasswordSet());
}

function* checkIsPasswordSetSaga() {
  const loginType = getCookie(CookiesKeys.login_type);
  if (loginType !== LoginType.NORMAL) {
    const resultIsPassworSet = yield call(handleCheckPasswordIsSet);
    yield put(
      setIsPasswordSet(resultIsPassworSet.data.password_is_set ?? false),
    );
    localStorage.setItem(
      LocalStorageKey.PasswordIsSet,
      resultIsPassworSet.data.password_is_set,
    );
    return !!resultIsPassworSet.data.password_is_set;
  }
}

function* getUserInfoSaga() {
  const cookieUser = getCookie(CookiesKeys.auth_data);

  try {
    if (!cookieUser) yield put(setLoading(true));
    if (!isEmpty(cookieUser?.token)) {
      const result = yield call(handleGetUserInfo);
      const resultChat = yield call(handleGetChatUserData);
      yield put(setChatUserData(resultChat));
      yield put(setUserData(result.data));
      let keyPair = getUserKeyPair();
      if (isEmpty(keyPair)) {
        const resultKey = yield call(handleGenerateKey);
        localStorage.setItem(
          LocalStorageKey.KeyPair,
          JSON.stringify(resultKey.data),
        );
        keyPair = resultKey.data;
      }
      yield call(checkIsPasswordSetSaga);
    } else {
      if (!window.location.pathname.includes("/auth")) {
        window.location.href = "/auth/login";
      }
    }
  } catch (error) {
    toast.error(getErrorMessage(error));
  } finally {
    yield put(setLoading(false));
  }
}

function* requestTokenSaga() {
  const permission = yield call(Notification.requestPermission);
  if (permission === "granted") {
    const token: string = yield call(getToken, messaging, {
      vapidKey: VAPID_KEY,
    });

    yield put(setFcmToken(token));

    onMessage(messaging, (payload) => {
      const messageSound = new Audio("/notification.mp3");
      messageSound.play();
    });
  } else if (permission === "denied") {
    //notifications are blocked
  }
}

function* registerDeviceSaga() {
  const fcmToken = yield select(selectFcmToken);
  const result = yield call(handleUpsertUserDevice, fcmToken);

  yield put(setUserDeviceInfo(result.data));
  register();
}

export function* watchAuth() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(logout, logoutSaga);
  yield takeLatest(loginSocial, loginSocialSaga);
  yield takeLatest(checkIsPasswordSet, checkIsPasswordSetSaga);
  yield takeLatest(getUserInfo, getUserInfoSaga);
  yield takeLatest(requestToken, requestTokenSaga);
  yield takeLatest(registerDevice, registerDeviceSaga);
}
