import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ChatState } from "../types";

export const selectChatState = (state: RootState): ChatState => state.chat;

export const selectRooms = createSelector(
  [selectChatState],
  (chat) => chat.rooms
);

export const selectRoomsSearch = createSelector(
  [selectChatState],
  (chat) => chat.roomsSearch
);

export const selectTotal = createSelector(
  [selectChatState],
  (chat) => chat.total
);

export const selectSelectedRoom = createSelector(
  [selectChatState],
  (chat) => chat.selectedRoom
);

export const selectSelectedRoomMembers = createSelector(
  [selectChatState],
  (chat) => chat.selectedRoomMembers
);

export const selectSelectedRoomCreatorUser = createSelector(
  [selectChatState],
  (chat) => chat.selectedRoomCreatorUser
);

export const selectIsLoading = createSelector(
  [selectChatState],
  (chat) => chat.isLoading
);

export const selectIsLoadMore = createSelector(
  [selectChatState],
  (chat) => chat.isLoadMore
);

export const selectSearch = createSelector(
  [selectChatState],
  (chat) => chat.search
);

export const selectSearchValue = createSelector(
  [selectChatState],
  (chat) => chat.searchValue
);

export const selectChatUnreadCount = createSelector(
  [selectChatState],
  (chat) => chat.chatUnreadCount
);

export const selectRoomInfo = createSelector(
  [selectChatState],
  (chat) => chat.roomInfo
);

export const selectIsOpenJoinRoomDialog = createSelector(
  [selectChatState],
  (chat) => chat.isOpenJoinRoomDialog
);
