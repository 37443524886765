import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { InputOTPType } from "../constant/profile";

export interface OTPTimeInfo {
  created_at: string;
  expired_at: string;
}

const OTPContext = createContext<{
  otpTimeInfo: Partial<Record<InputOTPType, OTPTimeInfo | undefined>>;
  setOtpTimeInfo: Dispatch<
    SetStateAction<Partial<Record<InputOTPType, OTPTimeInfo | undefined>>>
  >;
  onUpdateOTPTimeInfo: (
    type: InputOTPType,
    otpInfo: OTPTimeInfo | undefined,
  ) => void;
}>({
  otpTimeInfo: {},
  setOtpTimeInfo: () => {},
  onUpdateOTPTimeInfo: () => {},
});

const OTPContextProvider = ({ children }: PropsWithChildren) => {
  const [otpTimeInfo, setOtpTimeInfo] = useState<
    Partial<Record<InputOTPType, OTPTimeInfo | undefined>>
  >({});

  const onUpdateOTPTimeInfo = (
    type: InputOTPType | undefined,
    otpInfo: OTPTimeInfo | undefined,
  ) => {
    if (!type) return;
    setOtpTimeInfo({ ...otpTimeInfo, [type]: otpInfo });
  };

  return (
    <OTPContext.Provider
      value={{ otpTimeInfo, setOtpTimeInfo, onUpdateOTPTimeInfo }}
    >
      {children}
    </OTPContext.Provider>
  );
};

export const useOTPContext = () => {
  return useContext(OTPContext);
};

export default OTPContextProvider;
