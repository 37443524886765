import { RoomState } from "../redux/types";
import { UserMessage } from "../services";

export const sortMessages = (messages: UserMessage[]) => {
  // sort messages by createdAt acsending
  return messages.slice().sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};

export const sortRooms = (rooms: RoomState): RoomState => {
  if (!rooms || !rooms.rooms) return;
  const sortedEntries = Object.entries(rooms.rooms).sort(
    ([, roomA], [, roomB]) => {
      const dateA = roomA.latestMessageObject?.createdAt ?? roomA.createdAt;
      const dateB = roomB.latestMessageObject?.createdAt ?? roomB.createdAt;

      return new Date(dateB).getTime() - new Date(dateA).getTime();
    }
  );

  const sortedRooms = Object.fromEntries(sortedEntries);

  return {
    rooms: sortedRooms,
    ids: Object.keys(sortedRooms),
  };
};
