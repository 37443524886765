import { Stack } from "@mui/material";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PermissionNotification } from "../../components/app-notification/PermissionNotification";
import {
  ConnectionContextProvider,
  KeyExchangingProvider,
} from "../../contexts";
import RoomCallContextProvider from "../../contexts/RoomCallContext";
import { selectUserData } from "../../redux/selectors/auth";
import { useAppSelector } from "../../redux/store";
import SideBar from "./SideBar";

const DashboardLayout = () => {
  const userData = useAppSelector(selectUserData);

  const isAuthenticated = useMemo(() => !!userData?.id, [userData?.id]);
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <KeyExchangingProvider>
      <RoomCallContextProvider>
        <ConnectionContextProvider>
          <Stack direction="row" position={"relative"}>
            <PermissionNotification />

            {/* SideBar */}
            <SideBar />
            <Outlet />
          </Stack>
        </ConnectionContextProvider>
      </RoomCallContextProvider>
    </KeyExchangingProvider>
  );
};

export default DashboardLayout;
