import { Dialog, DialogTitle } from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { PASSWORD_STEP } from "../../constant/room";
import { selectIsPasswordSet } from "../../redux/selectors/auth";
import { useAppSelector } from "../../redux/store";
import ConfirmOTPAndSetNewPassword from "./ConfirmOTPAndSetNewPassword";
import RequestPassword from "./RequestPassword";

type SetPasswordStep = (typeof PASSWORD_STEP)[keyof typeof PASSWORD_STEP];

export default function SetPasswordDialog() {
  const isPasswordSet = useAppSelector(selectIsPasswordSet);
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState(0);

  const handleNextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);

  const handlePrevStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);

  const STEPS: { key: SetPasswordStep; content: ReactNode }[] = useMemo(
    () => [
      {
        key: PASSWORD_STEP.REQUEST_PASSWORD,
        content: (
          <RequestPassword
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            handleNextStep={handleNextStep}
          />
        ),
      },
      {
        key: PASSWORD_STEP.SET_PASSWORD,
        content: (
          <ConfirmOTPAndSetNewPassword
            handlePrevStep={handlePrevStep}
            newPassword={newPassword}
          />
        ),
      },
    ],
    [handleNextStep, handlePrevStep, newPassword],
  );

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
      open={!isPasswordSet}
    >
      <DialogTitle>Set Password</DialogTitle>
      {STEPS[step].content}
    </Dialog>
  );
}
