import { LocalStorageKey } from "../constant/local-storage";
import { UserKeyPair } from "../types/key-exchange";
import { LocalStorageDecryptedMessage } from "../types/local-storage";
import { TextInputModeType } from "../types/text-input";

export const getUserKeyPair = (): UserKeyPair | null => {
  try {
    const keyPair: UserKeyPair = JSON.parse(
      localStorage.getItem(LocalStorageKey.KeyPair)
    );
    return keyPair;
  } catch {
    return null;
  }
};

export const setSideBarWidth = (width: number) => {
  localStorage.setItem(LocalStorageKey.SideBarWidth, width.toString());
};

export const getSidebarWidth = () => {
  try {
    return JSON.parse(localStorage.getItem(LocalStorageKey.SideBarWidth) ?? "");
  } catch (_) {
    return null;
  }
};

export const getTextInputMode = (): TextInputModeType => {
  return (
    (localStorage.getItem(
      LocalStorageKey.TextInputMode
    ) as TextInputModeType) ?? "plain"
  );
};

export const setTextInputMode = (mode: TextInputModeType) => {
  localStorage.setItem(LocalStorageKey.TextInputMode, mode);
};

export const setLocalStorageRoomEncryptKey = (
  roomId: string,
  exchangeEncryptKey: string
) => {
  const previousKeys = JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomEncryptKey) ?? "{}"
  );
  localStorage.setItem(
    LocalStorageKey.RoomEncryptKey,
    JSON.stringify({
      ...previousKeys,
      [roomId]: exchangeEncryptKey,
    })
  );
};

export const getLocalStorageRoomEncryptKeys = (): Record<string, string> => {
  return JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomEncryptKey) ?? "{}"
  );
};

export const getOneLocalStorageRoomEncryptKey = (roomId: string) => {
  return getLocalStorageRoomEncryptKeys()[roomId] ?? "";
};

export const setLocalChatUnreadCount = (roomId: string, count: number) => {
  const previousCounts = JSON.parse(
    localStorage.getItem(LocalStorageKey.ChatUnreadCount) ?? "{}"
  );

  localStorage.setItem(
    LocalStorageKey.ChatUnreadCount,
    JSON.stringify({
      ...previousCounts,
      [roomId]: count,
    })
  );
};

export const getAllLocalChatUnreadCount = () => {
  return JSON.parse(
    localStorage.getItem(LocalStorageKey.ChatUnreadCount) ?? "{}"
  );
};

export const addLocalStorageDecryptedMessage = (
  roomId: string,
  userMessageId: string,
  userMessage: LocalStorageDecryptedMessage
) => {
  const previousMessages = JSON.parse(
    localStorage.getItem(LocalStorageKey.DecryptedMessage) ?? "{}"
  );
  localStorage.setItem(
    LocalStorageKey.DecryptedMessage,
    JSON.stringify({
      ...previousMessages,
      [roomId]: {
        ...(previousMessages[roomId] ?? {}),
        [userMessageId]: {
          content: userMessage.content,
          messageMeta: userMessage.messageMeta,
        },
      },
    })
  );
};

export const getDecryptedUserMessagesByRoomId = (roomId: string) => {
  return (
    JSON.parse(localStorage.getItem(LocalStorageKey.DecryptedMessage) ?? "{}")[
      roomId
    ] ?? {}
  );
};

export const getDecryptedUserMessageByUserMessageId = (
  roomId: string,
  userMessageId: string
) => {
  const messages = getDecryptedUserMessagesByRoomId(roomId);
  return messages?.[userMessageId] as LocalStorageDecryptedMessage;
};

export const getRoomEncryptKey = () => {
  return JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomEncryptKey) ?? "{}"
  );
};
