import {
  GetListRoomMessageResponse,
  OptionSetting,
  Room,
  RoomCall,
  UserRoomCallStatus,
} from "./types";

import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CustomerUserInfo } from "../components/create-room-dialog/types";
import { ROOM_STATUS } from "../constant/room";
import { ChatMessageContent } from "../types/chat-message";
import {
  ConnectedDevice,
  StartKeyExchangeRequest,
  UserOnlineStatus,
} from "../types/key-exchange";
import { PaginationLimitOffset, UpdateRoomType } from "../types/room";
import { UploadResult } from "../types/upload";
import { getErrorMessage } from "../utils/error";
import {
  ChatAxios,
  ChatWSAxios,
  CustomerAxios,
  GeneralSettingAxios,
  getRequestHeaders,
  MediaAxios,
  NotificationAxios,
} from "./axios";
import { RoomEncryptType, RoomKeyExchangeStatus } from "./types";
import { LoginFormData } from "../redux/types";
import { PROFILE_TYPE } from "../constant/profile";

export const handleUploadFile = async (uploadFile: File) => {
  const formData = new FormData();

  formData.append("file", uploadFile, uploadFile.name);

  formData.append(
    "request",
    JSON.stringify({
      file_name: uploadFile.name,
      slug: uuidv4(),
    })
  );
  formData.append("option_overwrite", "false");

  const result = await MediaAxios.postReq<{ data: UploadResult }>(
    "/media/create",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

export const handleChangeStatus = async (
  userId: string,
  roomId: string,
  isAccepted: boolean,
  allowGetPreviousMessage: boolean
) => {
  await ChatWSAxios.postReq("/api/user-room/change-request-status", {
    userId,
    roomId,
    statusType: isAccepted ? "joined" : "rejected",
    allowGetPreviousMessage,
  });
};

export const getListRequest = async (roomId: string) => {
  try {
    const result = await ChatWSAxios.getReq(
      "/api/user-room/get-requesting-list",
      {
        params: {
          roomId,
        },
      }
    );
    return result;
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleRemoveMember = async ({
  userId,
  roomId,
}: {
  userId: string;
  roomId: string;
}) => {
  try {
    await ChatWSAxios.postReq("/api/user-room/kick-member", {
      userId,
      roomId,
    });
    toast.success("Remove member successfully!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleBanMember = async (userId: string, roomId: string) => {
  try {
    await ChatWSAxios.postReq("/api/user-room/ban-member", {
      roomId,
      userId,
    });

    toast.success("Ban member successfully");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleChangeNickName = async (
  roomId: string,
  userId: string,
  nickname: string
) => {
  await ChatWSAxios.postReq("/api/user-room/update", {
    roomId,
    userId,
    nickname,
  });
};

export const handleJoinRoom = async (roomId: string) => {
  const result = await ChatWSAxios.postReq("/api/user-room/join", {
    roomId,
  });
  if (result) {
    if (result.status === ROOM_STATUS.JOINED) {
      toast.success("Join room successfully");
    } else {
      toast.success("Room join request sent!");
    }
  }
  return result;
};

export const handleDownload = (urlImage: string) => {
  axios({
    url: urlImage,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", urlImage.split("/").pop() ?? "file");
    document.body.appendChild(link);
    link.click();
  });
};

export const handleChangeAvatar = async (avatar: string) => {
  try {
    const result = await CustomerAxios.putReq("/user/update", {
      avatar,
    });
    await ChatWSAxios.putReq("/api/users/update", {
      avatar: result?.data?.user_profile_info?.avatar,
    });

    toast.success("Edit profile successfully!");
    return result.data;
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleChangeAvatarRoom = async (
  roomId: string,
  avatar: string
) => {
  try {
    await ChatWSAxios.postReq(`api/rooms/update/${roomId}`, {
      avatar,
    });
    toast.success("Upload avatar successfully!!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleLeaveRoom = async (roomId: string) => {
  await ChatWSAxios.postReq("/api/user-room/leave-room", {
    roomId,
  });
};

export const handleGetUserList = async (value: string) => {
  const result: AxiosResponse<CustomerUserInfo[]> = await CustomerAxios.getReq(
    "/user/get-list",
    {
      params: {
        page: 1,
        pageSize: 1000,
        search: value,
      },
    }
  );
  return result.data;
};

export const handleUpdateProfile = async (dataProfile) => {
  try {
    const result = await CustomerAxios.putReq("/user/update", dataProfile);
    toast.success("Update profile successfully!");
    return result.data;
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleCreateRoom = async (roomData) => {
  const result = await ChatWSAxios.postReq("/api/rooms/create", roomData);
  return result;
};

export const handleInviteMember = async (data) => {
  await ChatWSAxios.postReq("/api/user-room/add-member", data);
};

export const handleResendOTP = async () => {
  const result = await CustomerAxios.postReq("/external-user/request-password");
  return result;
};

export const handleEncryptMessageWithType = async (
  message: string,
  roomEncryptType: RoomEncryptType
) => {
  const result = await ChatAxios.postReq("/chat/encrypt-with-type", {
    message,
    type: roomEncryptType,
  });
  return result.data.message_encrypted;
};

export const handleEncryptMessageWithKey = async (data: {
  message: string;
  key: string;
}) => {
  const result = await ChatAxios.postReq("/chat/encrypt", data);
  return result.data.message_encrypted;
};

export const handleDecryptMessageWithType = async (
  message: string,
  roomEncryptType: RoomEncryptType
) => {
  const result = await ChatAxios.postReq<{
    data: {
      message_decrypted: string;
    };
  }>("/chat/decrypt-with-type", {
    message,
    type: roomEncryptType,
  });
  return result.data.message_decrypted;
};

export const handleDecryptMessageWithKey = async (data: {
  message: string;
  key: string;
}) => {
  const result = await ChatAxios.postReq<{
    data: {
      message_decrypted: string;
    };
  }>("/chat/decrypt", data);
  return result.data.message_decrypted;
};

export const handleGetMessageContent = async (idList: string[]) => {
  const result = await ChatAxios.postReq<{
    data: ChatMessageContent[];
  }>("/chat/get-list-message", {
    id_list: idList,
  });
  return result;
};

export const handleGetRoomOnlineMemberStatus = async (roomId: string) => {
  const result = await ChatWSAxios.getReq<Record<string, UserOnlineStatus>>(
    "/api/user-room-status/room-online-status/" + roomId
  );
  return result;
};

export const handleGetOneRoomNeedExchangeKey = async (roomId: string) => {
  const result = await ChatWSAxios.getReq<Room>(
    "/api/room-key-exchanges/get-one-room-need-to-exchange-key/" + roomId
  );
  return result;
};

export const handleGetRoomsNeedExchangeKey = async (
  params: PaginationLimitOffset
) => {
  const result = await ChatWSAxios.getReq<Room[]>(
    "/api/room-key-exchanges/get-rooms-need-to-exchange-key",
    {
      params,
    }
  );
  return result;
};

export const handleStartKeyExchange = async (data: StartKeyExchangeRequest) => {
  await ChatWSAxios.postReq(
    "/api/rooms/start-key-exchange/" + data.roomKeyExchangeId,
    data
  );
};

export const handleGetRoomOnlineMembers = async (roomId: string) => {
  const result = await ChatWSAxios.getReq<ConnectedDevice[]>(
    "/api/user-room-status/get-online-members/" + roomId
  );
  return result;
};

export const handleComputeKeyExchange = async (data: {
  private_key: string;
  public_key?: string;
  curve_params?: Record<string, string>;
}) => {
  const result = await ChatAxios.postReq<{ data: Record<string, string> }>(
    "/compute",
    data
  );
  return result;
};

export const handleUpdateRoomKeyExchange = async (data: {
  status: RoomKeyExchangeStatus;
  members?: Record<string, string>;
  roomKeyExchangeId: string;
  publicKey?: string;
}) => {
  await ChatWSAxios.putReq("/api/room-key-exchanges/update", data);
};

export const handleUpdateUserRoomStatusKeyExchange = async (data: {
  status: RoomKeyExchangeStatus;
  roomKeyExchangeId: string;
  members: Record<string, string>;
  publicKey: string;
}) => {
  await ChatWSAxios.putReq("/api/user-room-status-key-exchanges/update", data);
};

export const handleGetRoomKeyExchangeStatus = async (
  roomKeyExchangeId: string
) => {
  const result = await ChatWSAxios.getReq<RoomKeyExchangeStatus>(
    "/api/room-key-exchanges/get-status/" + roomKeyExchangeId
  );
  return result;
};

export const handleCreateRoomCall = async (
  roomId: string,
  userRoomId: string
) => {
  const result = await ChatWSAxios.postReq<{
    newRoomCall: RoomCall;
    newUserRoomCallStatus: UserRoomCallStatus;
  }>("/api/room-call/create", {
    roomId,
    userRoomId,
  });

  return result;
};

export const handleRequestPassword = async () => {
  const result = await CustomerAxios.postReq(
    "/external-user/request-password",
    {
      otp_resend: true,
    }
  );

  return result;
};

export const handleOtpChangePassword = async ({
  object,
  otp,
  otp_type,
}: {
  object: string;
  otp: number;
  otp_type: string;
}) => {
  await CustomerAxios.postReq(`/user/otp/change-password/verify`, {
    object,
    otp,
    otp_type,
  });
};

export const handleUserChangePassword = async ({
  old_password,
  new_password,
  otp,
}: {
  old_password: string;
  new_password: string;
  otp: number;
}) => {
  await CustomerAxios.postReq("/user/change-password", {
    old_password,
    new_password,
    otp,
  });
};

export const handleUpdateChangePhoneNumber = async ({
  otp,
  otp_type,
  phone_number,
}: {
  otp: number;
  otp_type: string;
  phone_number: number;
}) => {
  await CustomerAxios.putReq(
    "/user/private/update",
    {
      otp,
      otp_type,
      phone_number,
    },
    {
      headers: {
        "x-client-platform": "customer",
      },
    }
  );
};

export const handleUpdateChangeEmail = async (otp) => {
  await CustomerAxios.putReq("/user/update-email", {
    otp,
  });
};

export const handleDetailInfo = async (params: {
  roomKey?: string;
  roomId?: string;
}) => {
  const detailInfo = await ChatWSAxios.getReq<Room>("/api/rooms/get-one", {
    params,
  });

  return detailInfo;
};

export const handleGetRoomMessages = async (
  params: {
    roomId?: string;
    latestUserMessageId?: string;
  } & PaginationLimitOffset
) => {
  const result = await ChatWSAxios.getReq<GetListRoomMessageResponse>(
    "/api/messages/get-list",
    {
      params,
    }
  );

  return result;
};

export const handleGenerateKey = async () => {
  const result = await ChatAxios.getReq("generate-key", {
    headers: getRequestHeaders(),
  });

  return result;
};

export const handleGetUserInfo = async () => {
  const result = await CustomerAxios.getReq("/user/get-info");

  return result;
};

export const handleGetChatUserData = async () => {
  const result = await ChatWSAxios.getReq("api/users/get-info");

  return result;
};

export const handleLogin = async (values: LoginFormData) => {
  const result = await CustomerAxios.postReq("/user/login", values);

  return result;
};

export const handlePrivateInfo = async (password: string) => {
  const result = await CustomerAxios.postReq("/user/private/get", {
    password: password,
  });

  return result;
};

export const handleUpdateUserDevice = async (
  userDeviceInfoId: string,
  fcmToken: string
) => {
  NotificationAxios.putReq(`/user-device/update/${userDeviceInfoId}`, {
    device_token: fcmToken,
    operating_system: "web",
    device_id: navigator.userAgent,
    is_active: false,
  });
};

export const handleUpsertUser = async (id, username, email, avatar) => {
  await ChatWSAxios.postReq("/api/users/upsert", {
    id: id,
    username: username,
    email: email,
    avatar: avatar,
  });
};

export const handleUpsertUserDevice = async (fcmToken: string) => {
  const result = await NotificationAxios.postReq("/user-device/upsert", {
    device_token: fcmToken,
    operating_system: "web",
    device_id: navigator.userAgent,
    is_active: true,
  });

  return result;
};

export const handleCheckPasswordIsSet = async () => {
  const result = await CustomerAxios.getReq("/user/password-is-set");

  return result;
};

export const handleProcessCallBackGoogle = async (
  searchParams: URLSearchParams
) => {
  const result = await CustomerAxios.getReq(
    "/external-user/process-call-back-google?" + searchParams.toString()
  );

  return result;
};

export const handleProcessCallBackFacebook = async (
  searchParams: URLSearchParams
) => {
  const result = await CustomerAxios.getReq(
    "/external-user/process-call-back-facebook?" + searchParams.toString()
  );

  return result;
};

export const handleProcessCallBackApple = async (
  searchParams: URLSearchParams
) => {
  const result = await CustomerAxios.postReq(
    "/external-user/process-call-back-apple",
    {
      state: searchParams.get("state"),
      code: searchParams.get("code"),
    }
  );
  return result;
};

export const checkEmailIsAlreadyUsed = async (email: string) => {
  await CustomerAxios.getReq("/user/verify-user", {
    params: {
      username_email: email,
    },
  });
};

export const checkPhoneNumberIsAlreadyUsed = async (phone: string) => {
  await CustomerAxios.getReq("/user/verify-user", {
    params: {
      phone_number: phone,
    },
  });
};

export const handleForgotPassword = async (email: string) => {
  await CustomerAxios.postReq("/user/forgot-password", {
    object: email,
    otp_resend: true,
  });
};

export const handleVerifyOtp = async (email: string, otp: string) => {
  await CustomerAxios.postReq(`/otp/forget-password/verify`, {
    object: email,
    otp,
    otp_type: "email",
  });
};

export const handleResetPasswort = async (
  email: string,
  otp: string,
  newPassword: string
) => {
  await CustomerAxios.postReq("/user/reset-password", {
    object: email,
    otp: otp,
    new_password: newPassword,
  });
};

export const handleRequestOTP = async () => {
  const result = await CustomerAxios.postReq("user/otp/update-private/send", {
    otp_type: "email",
  });
  return result;
};

export const checkUsernameIsAlreadyUsed = async (username?: string) => {
  await CustomerAxios.getReq("/user/verify-user", {
    params: {
      username,
    },
  });
};

export const handleUpdateEmail = async (email: string) => {
  await CustomerAxios.putReq(
    "user/private/update",
    {
      otp_type: "email",
      email,
    },
    {
      headers: {
        "x-client-platform": "customer",
      },
    }
  );
};

export const handleUpdatePrivateProfile = async (
  otp: string,
  { username, bio }: { username?: string; bio?: string }
) => {
  if (otp) {
    await CustomerAxios.putReq(
      "/user/private/update",
      {
        otp,
        otp_type: PROFILE_TYPE.EMAIL,
        username,
      },
      {
        headers: {
          "x-client-platform": "customer",
        },
      }
    );
  }
  await ChatWSAxios.putReq("/api/users/update", {
    username,
    bio,
  });
};

export const handleUpdateEmailOTP = async (otp: string, email: string) => {
  await CustomerAxios.postReq("/user/update-email", {
    otp,
  });
  await ChatWSAxios.putReq("/api/users/update", {
    email,
  });
};

export const handleGetRoomList = async (
  search: string,
  param: PaginationLimitOffset
) => {
  const result = await ChatWSAxios.getReq<{
    rooms: Room[];
    total: number;
  }>("/api/rooms/get-list", {
    params: {
      search,
      ...(!search ? { joined: true } : {}),
      ...param,
    },
  });

  return result;
};
export const handleUpdatePhoneNumber = async (
  otp: number,
  phone_number: string
) => {
  await CustomerAxios.putReq(
    "/user/private/update",
    {
      otp,
      otp_type: PROFILE_TYPE.EMAIL,
      phone_number,
    },
    {
      headers: {
        "x-client-platform": "customer",
      },
    }
  );
};

export const handleVerifyPassword = async (email: string, otp: number) => {
  await CustomerAxios.postReq(`/user/otp/change-password/verify`, {
    object: email,
    otp,
    otp_type: PROFILE_TYPE.EMAIL,
  });
};

export const handleChangePassword = async (
  old_password: string,
  new_password: string,
  otp: number
) => {
  await CustomerAxios.postReq("/user/change-password", {
    old_password,
    new_password,
    otp,
  });
};

export const handleRegister = async (formValues, otp: string) => {
  await CustomerAxios.postReq("/user/register", {
    ...formValues,
    otp,
    otp_type: PROFILE_TYPE.EMAIL,
    object_system_key: "jf_jchat",
  });
};

export const handleExternalSetPassword = async (
  otp: string,
  password: string
) => {
  await CustomerAxios.postReq("/external-user/set-password", {
    otp,
    password,
  });
};

export const handleUpdateVerifyEmailToken = async (
  searchParams: URLSearchParams
) => {
  const result = await CustomerAxios.postReq(
    `user/update-email/verify/${searchParams.get("token")}`,
    {}
  );
  return result;
};

export const handleDeleteMessage = async (
  roomId: string,
  userMessageId: string
) => {
  await ChatWSAxios.deleteReq("/api/user-messages/delete", {
    roomId,
    userMessageId,
  });
};

export const handleDeleteRoom = async (roomId: string) => {
  await ChatWSAxios.deleteReq(`/api/rooms/delete/${roomId}`);
};

export const handleGetRoomInfo = async (roomKey: string) => {
  const result = await ChatWSAxios.getReq(`/api/rooms/get-info/${roomKey}`);
  return result;
};

export const handleUpdateRoomName = async (
  roomId: string,
  data: UpdateRoomType
) => {
  await ChatWSAxios.postReq(`/api/rooms/update/${roomId}`, { name: data.name });
};

export const handleTransferOwner = async (roomId: string, userId: string) => {
  await ChatWSAxios.postReq(`/api/user-room/transfer-owner`, {
    roomId,
    userId,
  });
};
export const handleGeneralSetting = async (key: string) => {
  const resultCanRegister = await GeneralSettingAxios.getReq<{
    data: OptionSetting;
  }>(`/option/get-one-by-key/${key}`);
  return resultCanRegister;
};

export const handleRestartRoomKeyExchange = async (roomId: string) => {
  await ChatWSAxios.postReq(`/api/rooms/${roomId}/restart-key-exchange/`);
};

export const handleGetMessageInfo = async (messageId: string) => {
  const result = await ChatWSAxios.getReq("/api/messages/get-one", {
    params: {
      messageId,
    },
  });
  return result;
};

export const handleGetThreadMessages = async (messageId: string) => {
  const result = await ChatWSAxios.getReq(
    `/api/messages/${messageId}/thread-messages/get-list`
  );
  return result;
};

export const handleLoginSocialGoogle = async () => {
  const result = await CustomerAxios.getReq("/external-user/login-with-google");
  return result;
};
