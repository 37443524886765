import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../types";

export const initialState: AuthState = {
  userData: null,
  chatUserData: null,
  isPasswordSet: true,
  fcmToken: "",
  userDeviceInfo: undefined,
  loading: false,
  loadingLogin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setChatUserData: (state, action) => {
      state.chatUserData = action.payload;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setIsPasswordSet: (state, action) => {
      state.isPasswordSet = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingLogin: (state, action) => {
      state.loadingLogin = action.payload;
    },
    setUserDeviceInfo: (state, action) => {
      state.userDeviceInfo = action.payload;
    },
    logout: () => {},
    login: (state, action) => {},
    loginSocial: (state, action) => {},
    checkIsPasswordSet: () => {},
    getUserInfo: () => {},
    requestToken: () => {},
    registerDevice: () => {},
    clearAuth: () => initialState,
  },
});

export const {
  setUserData,
  setChatUserData,
  setFcmToken,
  setIsPasswordSet,
  logout,
  login,
  loginSocial,
  checkIsPasswordSet,
  getUserInfo,
  setLoading,
  requestToken,
  setUserDeviceInfo,
  registerDevice,
  setLoadingLogin,
  clearAuth,
} = authSlice.actions;

export default authSlice.reducer;
