import { call, put, select, takeLatest } from "redux-saga/effects";
import { getConfigSettings, setSettings } from "../slices/setting";
import { handleGeneralSetting } from "../../services/api";
import { CookiesKeys, getCookie, setCookie } from "../../utils/cookie";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils/error";
import { SETTINGS_KEYS } from "../types";
import { selectSettings } from "../selectors/setting";

function* getConfigSettingsSaga() {
  try {
    const settings = yield select(selectSettings);
    const cookieAuthData = getCookie(CookiesKeys.auth_data);

    if (settings?.canRegister === undefined || isEmpty(cookieAuthData)) {
      const resultCanRegister = yield call(
        handleGeneralSetting,
        SETTINGS_KEYS.user_register_enable
      );
      const resultRoomSupport = yield call(
        handleGeneralSetting,
        SETTINGS_KEYS.support_room_url
      );

      const newSettings = {
        canRegister: resultCanRegister.data.value === "true",
        support_room_url: resultRoomSupport,
      };
      yield put(setSettings(newSettings));
    }
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
}

export function* watchSetting() {
  yield takeLatest(getConfigSettings, getConfigSettingsSaga);
}
