import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { selectedOtpTimeInfo } from "../../redux/selectors/otp";
import { useAppSelector } from "../../redux/store";

import { InputOTPType, TYPE_OTP } from "../../constant/profile";
import { formatRelativeTime } from "../../helpers/time";
import { useCountdown } from "../../hooks/useCountdown";

import { getDisplayKeyFromPrefixString } from "../../helpers/prefix-helper";
import { selectUserData } from "../../redux/selectors/auth";
import {
  handleChangePassword,
  handleExternalSetPassword,
  handleRegister,
  handleUpdateEmailOTP,
  handleUpdatePhoneNumber,
  handleUpdatePrivateProfile,
  handleVerifyPassword,
} from "../../services/api";
import { getErrorMessage } from "../../utils/error";

const OTP_LENGTH = 6;

const validationSchema = yup.object({
  otp: yup
    .string()
    .required("Please input this field")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(OTP_LENGTH, `Must be exactly ${OTP_LENGTH} digits`)
    .max(OTP_LENGTH, `Must be exactly ${OTP_LENGTH} digits`),
});

function InputOTP({
  type,
  formValues,
  handleSuccess,
  handleBack,
  handleResendOTP,
}: {
  type?: InputOTPType;
  formValues: any;
  handleSuccess: () => void;
  handleBack: () => void;
  handleResendOTP?: (formValues: any) => Promise<void>;
}) {
  const otpTimeInfo = useAppSelector(selectedOtpTimeInfo);
  const userData = useAppSelector(selectUserData);

  const otpInfo = useMemo(
    () => (type ? otpTimeInfo[type] : undefined),
    [otpTimeInfo, type],
  );

  const [_, __, minutes, seconds] = useCountdown(otpInfo?.expired_at);
  const isExpired = useMemo(
    () => minutes <= 0 && seconds <= 0,
    [minutes, seconds],
  );

  const onSubmit = async (values) => {
    switch (type) {
      case TYPE_OTP.CHANGE_PASSWORD:
        try {
          await handleVerifyPassword(formValues?.email, values.otp);
          await handleChangePassword(
            formValues?.old_password,
            formValues?.new_password,
            values.otp,
          );

          await handleSuccess();
          reset();
        } catch (error) {
          toast.error(getErrorMessage(error));
        }
        break;
      case TYPE_OTP.CHANGE_PHONE:
        try {
          await handleUpdatePhoneNumber(values.otp, formValues);
          reset();
          await handleSuccess();
        } catch (error) {
          console.log("error: ", error);
          toast.error(getErrorMessage(error));
        }

        break;
      case TYPE_OTP.CHANGE_USERNAME:
        try {
          handleUpdatePrivateProfile(values.otp, { username: formValues });
          reset();
          await handleSuccess();
        } catch (error) {
          console.log("error: ", error);
          toast.error(getErrorMessage(error));
        }

        break;

      case TYPE_OTP.CHANGE_EMAIL:
        try {
          await handleUpdateEmailOTP(values.otp, formValues.email);
          reset();
          handleSuccess();
        } catch (error) {
          toast.error(getErrorMessage(error));
        }

        break;

      case TYPE_OTP.REGISTER:
        try {
          await handleRegister(formValues, values.otp);
          reset();
          await handleSuccess();
        } catch (error) {
          toast.error(getErrorMessage(error));
        }
        break;

      case TYPE_OTP.EXTERNAL_SET_PASSWORD: {
        try {
          await handleExternalSetPassword(values.otp, formValues);
          reset();
          await handleSuccess();
        } catch (error) {
          toast.error(getErrorMessage(error));
        }
        break;
      }
      default:
        break;
    }
  };

  const methods = useForm({
    defaultValues: {
      otp: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;

  const watchOtp = watch("otp");

  const [loadingResendOTP, setLoadingResendOTP] = useState(false);

  const resendOTP = async () => {
    try {
      setLoadingResendOTP(true);

      if (handleResendOTP) {
        await handleResendOTP(formValues);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoadingResendOTP(false);
    }
  };

  return (
    <Stack justifyContent={"center"} alignContent={"center"} gap={2}>
      <Stack gap={0.5} justifyContent={"center"} alignContent={"center"}>
        <Typography variant="h6" textAlign={"center"}>
          OTP verification
        </Typography>
        <Typography textAlign={"center"} variant="body2">
          Please enter OTP sent to <br />
          <Typography sx={{ display: "inline", fontWeight: 600 }}>
            {formValues?.email ??
              getDisplayKeyFromPrefixString(userData.user_profile_info.email)}
          </Typography>
        </Typography>
        {!isExpired && otpInfo && (
          <Typography
            textAlign={"center"}
            sx={{
              fontWeight: 600,
            }}
            color={"text.primary"}
            variant="h5"
          >
            {formatRelativeTime({
              minutes,
              seconds,
            })}
          </Typography>
        )}
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <MuiOtpInput
            length={OTP_LENGTH}
            onChange={(otp) => setValue("otp", otp)}
            value={watchOtp}
          />

          <Button type="submit" disabled={isSubmitting} variant="contained">
            Confirm OTP
          </Button>
          <Button type="submit" onClick={handleBack} variant="text">
            Cancel
          </Button>
        </Stack>
      </form>

      {isExpired && (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Typography>Did not receive OTP</Typography>
          <Button
            disabled={loadingResendOTP}
            onClick={resendOTP}
            variant="text"
          >
            Resend
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default InputOTP;
