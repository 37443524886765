import { DialogProps } from "@mui/material/Dialog";
import { PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { isArray } from "lodash";
import React from "react";
import { UseFormReset } from "react-hook-form";
import { batch } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { FormDataType } from "../../components/create-room-dialog/CreateRoomDialog";
import { CustomerUserInfo } from "../../components/create-room-dialog/types";
import {
  DISPLAY_TYPE,
  ENCRYPT_TYPE,
  ROOM_STATUS,
  TYPE_FC_USER,
} from "../../constant/room";
import { parseJSON } from "../../helpers/message-helper";
import { LatestMessage, Room, UserRoom, UserRoomStatus } from "../../services";
import {
  getListRequest,
  handleBanMember,
  handleChangeAvatarRoom,
  handleChangeNickName,
  handleChangeStatus,
  handleCreateRoom,
  handleDeleteRoom,
  handleDetailInfo,
  handleGetRoomInfo,
  handleGetRoomList,
  handleInviteMember,
  handleJoinRoom,
  handleLeaveRoom,
  handleRemoveMember,
  handleTransferOwner,
  handleUpdateRoomName,
} from "../../services/api";
import {
  PaginationLimitOffset,
  RoomInfo,
  UpdateRoomType,
} from "../../types/room";
import { mergeArrays } from "../../utils/array";
import { handleDecryptMessageContent } from "../../utils/encrypt";
import { getErrorMessage } from "../../utils/error";
import {
  selectRooms,
  selectSearch,
  selectSearchValue,
  selectSelectedRoom,
  selectTotal,
} from "../selectors/chat";
import {
  addMember,
  changeNickName,
  changeSelectedRoom,
  createRoom,
  deleteMember,
  deleteRoom,
  fetchRequestList,
  getRoomDetail,
  getRoomList,
  joinRoom,
  leaveRoom,
  removeRoom,
  setIsLoading,
  setIsLoadMore,
  setRooms,
  setSearch,
  setSearchValue,
  setTotal,
  updateJoinRequest,
  updateRoomAvatar,
  updateRoomName,
  upsertRoom,
  upsertRoomsSearch,
  upsertSelectedRoom,
} from "../slices/chat";
import { removeMessage } from "../slices/message";
import { RoomState } from "../types";

const updateRooms = produce((rooms: RoomState, room: Room) => {
  rooms.rooms[room.id] = room;
  if (!rooms.ids.includes(room.id)) {
    rooms.ids.push(room.id);
  }
});

function* getRoomListSaga(
  action: PayloadAction<{
    search: string;
    param: PaginationLimitOffset;
    isCached?: boolean;
  }>
) {
  const { search, param, isCached } = action.payload;
  const emptyRooms = { rooms: {}, ids: [] };
  const searchValue = yield select(selectSearch);
  const currentRooms = yield select(selectRooms);
  const prevTotal = yield select(selectTotal);

  let newRooms: RoomState = currentRooms;
  const isSearchUnchanged = searchValue === search;

  if (isSearchUnchanged || !isCached) {
    yield put(setIsLoading(true));
    newRooms = emptyRooms;

    if (!isSearchUnchanged && !isCached) {
      yield put(setRooms(emptyRooms));
    }
  } else if (!isSearchUnchanged) {
    newRooms = emptyRooms;
  }

  try {
    const result = yield call(handleGetRoomList, search, param);

    for (const room of result.rooms ?? []) {
      let latestMessage = parseJSON<LatestMessage>(room.latestMessage ?? "{}");

      if (latestMessage.id && room.encryptType !== ENCRYPT_TYPE.NONE) {
        try {
          const message = yield call(
            handleDecryptMessageContent,
            latestMessage,
            room
          );
          latestMessage = { ...latestMessage, ...message };
        } catch (error) {
          console.error("Error while decrypting message", error);
        }
      }

      const updatedRoom = { ...room, latestMessageObject: latestMessage };
      newRooms = updateRooms(newRooms, updatedRoom);
    }

    const newTotal = newRooms.ids.length;
    const isTotalChanged = prevTotal !== newTotal;
    const isLessThanLimit = result.total < param.limit;

    yield put((dispatch) => {
      batch(() => {
        dispatch(
          setIsLoadMore(
            (!isSearchUnchanged || isTotalChanged) && !isLessThanLimit
          )
        );
        dispatch(upsertRoomsSearch({ rooms: newRooms, id: search }));
        dispatch(setRooms(newRooms));
        dispatch(setTotal(newRooms.ids.length));
        dispatch(setSearch(search));
      });
    });
  } catch (error) {
    console.error("Error while fetching rooms", error);
    toast.error("Error while fetching rooms");
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getRoomDetailSaga(action: {
  payload: {
    roomKey: string;
    navigate: NavigateFunction;
  };
}) {
  const { roomKey, navigate } = action.payload;

  try {
    const room = yield call(handleDetailInfo, { roomKey });
    const latestMessage = parseJSON<LatestMessage>(room.latestMessage ?? "{}");
    const decryptedMessage = yield call(
      handleDecryptMessageContent,
      latestMessage,
      room
    );

    const updatedRoom = {
      ...room,
      latestMessageObject: decryptedMessage,
      status: ROOM_STATUS.JOINED,
    };
    yield put(upsertRoom({ room: updatedRoom }));
    yield put(upsertSelectedRoom({ room: updatedRoom }));
  } catch (error) {
    toast.error(getErrorMessage(error));
    navigate("/app");
  }
}

function* changeSelectedRoomSaga(action: {
  payload: {
    roomKey: string;
    setRoomInfo: (value: React.SetStateAction<RoomInfo>) => void;
    setOpenDialogJoinRoom: (value: React.SetStateAction<boolean>) => void;
    navigate: NavigateFunction;
  };
}) {
  const { roomKey, setRoomInfo, setOpenDialogJoinRoom, navigate } =
    action.payload;

  try {
    const rooms = yield select(selectRooms);
    const idFinded = rooms.ids.find(
      (id: string) => rooms.rooms[id].key === roomKey
    );

    if (idFinded && rooms.rooms[idFinded].status === ROOM_STATUS.JOINED) {
      // yield put(upsertSelectedRoom({ room: rooms.rooms[idFinded] }));
      yield put(getRoomDetail({ roomKey, navigate }));
    } else {
      const result = yield call(handleGetRoomInfo, roomKey);

      if (result.joinStatus === ROOM_STATUS.JOINED) {
        yield put(getRoomDetail({ roomKey, navigate }));
      } else {
        setRoomInfo(result);
        setOpenDialogJoinRoom(true);
      }
    }
  } catch (error) {
    toast.error(getErrorMessage(error));
    navigate("/app");
  }
}

function* deleteMemberSaga(
  action: PayloadAction<{
    userId: string;
    title: string;
    clearConfirmDialog: () => void;
    setLoadingConfirm: (value: React.SetStateAction<boolean>) => void;
  }>
) {
  const { userId, title, clearConfirmDialog, setLoadingConfirm } =
    action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  try {
    setLoadingConfirm(true);
    if (title === TYPE_FC_USER.REMOVE) {
      yield call(handleRemoveMember, { userId, roomId: selectedRoom.id });
    } else {
      yield call(handleBanMember, userId, selectedRoom.id);
    }

    const listUserRemove = (selectedRoom.users ?? []).filter(
      (item) => item.userId === userId
    );
    const updatedMembers = (selectedRoom.users ?? []).filter(
      (existingMember) =>
        !listUserRemove.some(
          (memberToRemove) => memberToRemove.userId === existingMember.User.id
        )
    );

    const rooms = yield select(selectRooms);
    const newRoom = rooms.rooms[selectedRoom.id] || selectedRoom;
    const updatedRoom = { ...newRoom, users: updatedMembers };

    yield put(upsertRoom({ room: updatedRoom }));
    yield put(upsertSelectedRoom({ room: updatedRoom }));
  } catch (error) {
    console.error("Error modifying member:", error);
  } finally {
    setLoadingConfirm(false);
    clearConfirmDialog();
  }
}

function* leaveRoomSaga(
  action: PayloadAction<{
    selectedAdmin: UserRoom;
    isCreator: boolean;
    navigate: NavigateFunction;
    setLoadingLeave: (value: React.SetStateAction<boolean>) => void;
    setOpenLeave: (value: React.SetStateAction<boolean>) => void;
  }>
) {
  const { selectedAdmin, isCreator, navigate, setLoadingLeave, setOpenLeave } =
    action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  try {
    setLoadingLeave(true);
    if (isCreator)
      yield call(handleTransferOwner, selectedRoom.id, selectedAdmin.userId);
    yield call(handleLeaveRoom, selectedRoom.id);
    yield put(upsertSelectedRoom({ room: undefined }));
    yield put(removeRoom({ roomId: selectedRoom.id }));
    navigate("/app");
    setOpenLeave(false);
    toast.success("Leave room successfully!");
    yield put(removeMessage({ roomId: selectedRoom.id }));
  } catch (error) {
    toast.error(getErrorMessage(error));
    setLoadingLeave(false);
  } finally {
    setLoadingLeave(false);
  }
}

function* deleteRoomSaga(
  action: PayloadAction<{
    roomId: string;
    navigate: NavigateFunction;
    handleClose: () => void;
    setIsLoading: (value: React.SetStateAction<boolean>) => void;
  }>
) {
  const { roomId, navigate, handleClose, setIsLoading } = action.payload;
  try {
    setIsLoading(true);
    yield call(handleDeleteRoom, roomId);
    yield put(removeRoom({ roomId: roomId, isDelete: true }));
    navigate("/app");
    toast.success("Delete chat successfully!");
    yield put(removeMessage({ roomId }));
  } catch (error) {
    toast.error(getErrorMessage(error));
    setIsLoading(false);
  } finally {
    handleClose();
    setIsLoading(false);
  }
}

function* createRoomSaga(
  action: PayloadAction<{
    data: FormDataType;
    navigate: NavigateFunction;
    setIsLoading: (value: React.SetStateAction<boolean>) => void;
    dialogProps: DialogProps;
    reset: UseFormReset<FormDataType>;
    setStep: (value: React.SetStateAction<number>) => void;
  }>
) {
  const { data, navigate, setIsLoading, dialogProps, reset, setStep } =
    action.payload;
  try {
    setIsLoading(true);
    const roomData = {
      room: {
        name: data.name,
        messageType: data.messageType,
        displayType: data.displayType,
        connectionType: data.connectionType,
        encryptType: data.encryptType,
      },
      roomMembers: data.roomMembers.map((item: CustomerUserInfo) => ({
        id: item.id,
        email: item.user_profile_info.email,
        username: item.username,
      })),
    };
    const result = yield call(handleCreateRoom, roomData);
    yield put(upsertRoom({ room: { ...result, status: ROOM_STATUS.JOINED } }));
    yield put(upsertSelectedRoom({ room: result }));
    yield put(setSearchValue(""));
    dialogProps.onClose?.({}, "escapeKeyDown");
    toast.success("Create new room successfully");
    reset();
    setStep(0);
    navigate(`/app/${result.key}`);
  } catch (error) {
    toast.error(getErrorMessage(error));
    setIsLoading(true);
  } finally {
    setIsLoading(false);
  }
}

function* addMemberSaga(
  action: PayloadAction<{
    addingUser: CustomerUserInfo[];
    allowGetPreviousMessage: boolean;
    setLoadingAddUser: (value: React.SetStateAction<boolean>) => void;
    dialogProps: DialogProps;
  }>
) {
  const {
    addingUser,
    allowGetPreviousMessage,
    setLoadingAddUser,
    dialogProps,
  } = action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  const rooms = yield select(selectRooms);
  try {
    setLoadingAddUser(true);
    const data = {
      roomId: selectedRoom.id,
      memberList: addingUser.map((item) => ({
        id: item.id,
        email: item.user_profile_info.email,
        username: item.username,
        allowGetPreviousMessage:
          selectedRoom?.displayType === DISPLAY_TYPE.NORMAL
            ? allowGetPreviousMessage
            : selectedRoom?.displayType === DISPLAY_TYPE.PUBLIC,
      })),
    };
    const newDataUsersToAdd = [];
    addingUser.map((item) => {
      return newDataUsersToAdd.push({
        ...item,
        Room: selectedRoom,
        userId: item.id,
        User: {
          ...item.user_profile_info,
          username: item.username,
          id: item.id,
          email: item.user_profile_info.email,
          avatar: item.user_profile_info.avatar,
          isActive: true,
        },
        roomId: selectedRoom?.id,
      });
    });
    yield call(handleInviteMember, data);
    if (isArray(newDataUsersToAdd) && newDataUsersToAdd.length > 0) {
      const updatedMembers = mergeArrays(
        "id",
        selectedRoom.users,
        newDataUsersToAdd
      );
      const newRoom = rooms.rooms[selectedRoom?.id];
      const updatedRoom = { ...newRoom, users: updatedMembers };
      yield put(upsertRoom({ room: updatedRoom }));
      yield put(upsertSelectedRoom({ room: updatedRoom }));
    }
    yield call(toast.success, "Add users successfully!");
    dialogProps.onClose?.({}, "escapeKeyDown");
  } catch (error) {
    toast.error(getErrorMessage(error));
    setLoadingAddUser(false);
  } finally {
    setLoadingAddUser(false);
  }
}

function* updateRoomNameSaga(
  action: PayloadAction<{
    data: UpdateRoomType;
    setLoading: (value: React.SetStateAction<boolean>) => void;
    onClose: () => void;
  }>
) {
  const { data, setLoading, onClose } = action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  try {
    setLoading(true);
    yield call(handleUpdateRoomName, selectedRoom.id, data);

    const updatedRoom = {
      ...selectedRoom,
      name: data.name,
    };

    yield put(upsertRoom({ room: updatedRoom }));
    yield put(upsertSelectedRoom({ room: updatedRoom }));
    toast.success("Update room name successfully!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  } finally {
    setLoading(false);
    onClose();
  }
}

function* updateRoomAvatarSaga(
  action: PayloadAction<{
    avatar: string;
  }>
) {
  const { avatar } = action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  try {
    yield call(handleChangeAvatarRoom, selectedRoom.id, avatar);
    const updatedRoom = { ...selectedRoom, avatar: avatar };
    yield put(upsertRoom({ room: updatedRoom }));
    yield put(upsertSelectedRoom({ room: updatedRoom }));
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
}

function* updateJoinRequestSaga(
  action: PayloadAction<{
    id: string;
    isAccepted: boolean;
    allowGetPreviousMessage: boolean;
    setRequestList: (value: React.SetStateAction<UserRoomStatus[]>) => void;
    setOpen: (value: boolean) => void;
  }>
) {
  const { id, isAccepted, allowGetPreviousMessage, setRequestList, setOpen } =
    action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  try {
    yield call(
      handleChangeStatus,
      id,
      selectedRoom?.id,
      isAccepted,
      allowGetPreviousMessage
    );
    setRequestList((pre) => pre?.filter((item) => item.UserRoom.userId !== id));
    setOpen(false);
    toast.success("Change status successfully!!!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
}

function* fetchRequestListSaga(
  action: PayloadAction<{
    setRequestList: (value: React.SetStateAction<UserRoomStatus[]>) => void;
  }>
) {
  const { setRequestList } = action.payload;
  const selectedRoom = yield select(selectSelectedRoom);
  try {
    const result = yield call(getListRequest, selectedRoom.id);
    setRequestList(result);
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
}

function* changeNickNameSaga(
  action: PayloadAction<{
    roomId: string;
    memberId: string;
    nickname: string;
    setLoading: (value: boolean) => void;
    onClose: () => void;
  }>
) {
  const { roomId, memberId, nickname, setLoading, onClose } = action.payload;

  if (!roomId || !memberId || !nickname.trim()) {
    toast.error("Invalid data. Please check again.");
    return;
  }

  const rooms = yield select(selectRooms);
  const room = rooms.rooms?.[roomId];

  if (!room) {
    toast.error("Room not found.");
    return;
  }

  setLoading(true);

  try {
    yield call(handleChangeNickName, roomId, memberId, nickname);

    const updatedRoom = {
      ...room,
      users: room.users.map((user: UserRoom) =>
        user.userId === memberId ? { ...user, nickname } : user
      ),
    };

    yield put(upsertRoom({ room: updatedRoom }));
    yield put(upsertSelectedRoom({ room: updatedRoom }));

    toast.success("Change nickname successfully!");
    onClose();
  } catch (error) {
    toast.error(getErrorMessage(error));
    console.error("Error changing nickname:", error);
  } finally {
    setLoading(false);
  }
}

function* joinRoomSaga(
  action: PayloadAction<{
    room: RoomInfo;
    navigate: NavigateFunction;
    setLoading: (value: React.SetStateAction<boolean>) => void;
    clearConfirmDialog?: () => void;
    onClose?: () => void;
  }>
) {
  const { room, navigate, setLoading, clearConfirmDialog, onClose } =
    action.payload;
  const rooms = yield select(selectRooms);
  try {
    setLoading(true);
    const result = yield call(handleJoinRoom, room.id);
    if (setLoading && result.status !== ROOM_STATUS.JOINED) {
      navigate("/");
      return;
    }
    const roomInfo = rooms.rooms[room.id];
    if (!roomInfo) {
      yield put(getRoomDetail({ roomKey: room.key, navigate }));
      return;
    }
    const updatedRoom = {
      ...roomInfo,
      status: result.status,
    };
    yield put(upsertRoom({ room: updatedRoom }));
    if (onClose) yield put(upsertSelectedRoom({ room: updatedRoom }));
    if (!onClose && result.status === ROOM_STATUS.JOINED) {
      navigate(`/app/${room?.key}`);
    }
  } catch (error) {
    toast.error(getErrorMessage(error));
  } finally {
    if (onClose) onClose();
    else clearConfirmDialog();
    setLoading(false);
  }
}

export function* watchChat() {
  yield takeLatest(getRoomList, getRoomListSaga);
  yield takeLatest(getRoomDetail, getRoomDetailSaga);
  yield takeLatest(deleteMember, deleteMemberSaga);
  yield takeLatest(leaveRoom, leaveRoomSaga);
  yield takeLatest(deleteRoom, deleteRoomSaga);
  yield takeLatest(changeSelectedRoom, changeSelectedRoomSaga);
  yield takeLatest(createRoom, createRoomSaga);
  yield takeLatest(addMember, addMemberSaga);
  yield takeLatest(updateRoomName, updateRoomNameSaga);
  yield takeLatest(updateRoomAvatar, updateRoomAvatarSaga);
  yield takeLatest(updateJoinRequest, updateJoinRequestSaga);
  yield takeLatest(fetchRequestList, fetchRequestListSaga);
  yield takeLatest(changeNickName, changeNickNameSaga);
  yield takeLatest(joinRoom, joinRoomSaga);
}
